import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png'
import Amplify, { Auth } from 'aws-amplify';
import Footer from "../../pages/footer";
import useRegisterForm from "./useRegisterForm";
import { isRequired } from '../../utils/validate'
import ValidateField from '../../utils/useValid'
import customLogger from "../../common/loggerService";
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import { ValidateName, AccountFieldNameConstant } from "../../constants/validateMsgConstant";
import { showErrorToastr } from "../../common/toastrService";
import { commonImages } from "../../constants";
import { Link } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import GoogleIcon from "../../assets/images/icons/Google-icon.png";
import facebookIcon from "../../assets/images/icons/Facebook-icon.png";
import useLogoImage from "../../hooks/useLogoImage";
const formValues = {
    firstName: '',
    lastName: '',
    phoneNo: '',
    email: '',
    password: ''
}
const CreateAccount = () => {
    const [showSignin, setSignin] = useState("Email");
    const [values, setValues] = useState(formValues)
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [currentCountryCode, setCurrentCountryCode] = useState('');
    const [logoUrl, setLogoUrl] = useLogoImage();

    const handleChangeEmail = (e) => {
        setSignin(e.target.value);
    }

    const navigate = useNavigate();

    const HandleChange = (e) => {
        const { name, value } = e.target
        setErrors({
            ...errors,
            [name]: ""
        });
        setValues({
            ...values,
            [name]: value,
        });
    }

    const HandleChangePhone = (value, name) => {
        const item = value && formatPhoneNumber(value)
        setCurrentCountryCode(item)
        setErrors({
            ...errors,
            [name]: ""
        });
        setValues({
            ...values,
            [name]: value,
        });
    };

    const HandleBlur = (e) => {
        const { name, value } = e.target;
        const fieldErrors = validation({
            ...values,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: fieldErrors[name]
        });
    };

    const validation = (values) => {
        const errors = {};
        errors.firstName = ValidateField(
            values.firstName,
            AccountFieldNameConstant?.FIRSTNAME,
            [ValidateName?.REQUIRED, ValidateName?.ISTEXT_ONLY],
        );

        errors.lastName = ValidateField(
            values.lastName,
            AccountFieldNameConstant?.LASTNAME,
            [ValidateName?.REQUIRED, ValidateName?.ISTEXT_ONLY, ValidateName?.LASTNAME_LENGTH],
        );
        if (currentCountryCode) {
            errors.phoneNo = ValidateField(
                values.phoneNo,
                AccountFieldNameConstant?.PHONE_NUMBER,
                [ValidateName?.REQUIRED, ValidateName?.ISVALID_PHONE],
            );
        }

        errors.email = ValidateField(
            values.email,
            AccountFieldNameConstant?.EMAIL,
            [ValidateName?.REQUIRED, ValidateName?.ISVALID_EMAIL],
        );

        errors.password = ValidateField(
            values?.password,
            AccountFieldNameConstant?.PASSWORD,
            [ValidateName?.REQUIRED, ValidateName?.LOGINPASSWORD],
        );
        let error = {}
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined)
                error[key] = errors[key]
        })
        return error
    }

    const signUpWithEmail = (e) => {
        e.preventDefault();
        setErrors(validation(values));
        setIsSubmit(true);
    }

    useEffect(() => {
        const SignUp = async () => {
            try {
                const params = {
                    username: values.email,
                    email: values.email,
                    password: values.password,
                    attributes: {
                        'custom:firstName': values.firstName,
                        'custom:lastName': values.lastName,
                        'custom:role': "CUSTOMER",
                    },
                    status: "ACTIVE",
                    createdAt: new Date(),
                };
                if (formatPhoneNumber(values.phoneNo)) {
                    params.attributes.phone_number = values.phoneNo;
                }
                await Auth.signUp(params);
                localStorage.setItem('username', values.email);
                navigate('/otp-verification');
            } catch (error) {
                customLogger.error(error);
                showErrorToastr(error.message);
            }
        };
        if (Object.keys(errors).length === 0 && isSubmit) {
            SignUp();
        }
    }, [errors]);

    const signUpWithPhoneno = async (e) => {
        e.preventDefault();
        const anyFieldIsEmpty = Object.values(values).some((value) => value === '');
        if (anyFieldIsEmpty) {
            const errorsList = { ...errors };
            Object.keys(values).forEach((field) => {
                errorsList[field] = ValidateField(values[field], field, isRequired);
            });

            setErrors(errorsList);
        } else {
            try {
                const params = {
                    username: values.phoneNo,
                    password: values.password,
                    phoneNumbers: values.phoneNo,
                    email: values.email,
                    attributes: {
                        email: values.email,
                        phone_number: values.phoneNo,
                        'custom:firstName': values.firstName,
                        'custom:lastName': values.lastName,
                        'custom:role': 'customer',
                    }
                };

                await Auth.signUp(params);
                localStorage.setItem('username', values.phoneNo)
            } catch (error) {
                customLogger.error(error);
                throw error;
            }
        }
    };


    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <>
            <div className="login-page signup">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec  pt-5">
                                <div className="logo-sec">
                                    <Link to="/" style={{ color: "inherit" }} className="text-decoration-none">
                                        <div class="back-btn" style={{ margin: "-2.5rem 0" }}>
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                    </Link>
                                </div>
                                <div className="login-form">
                                    <form>
                                        <Row>
                                            <Col md={12}>
                                                <h1>Create Account</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <p style={{ textAlign: 'center' }}>
                                                    Create an account with your email address

                                                    {/* or mobile number */}
                                                    .</p>
                                                {/* <div class="form-group mt-2">
                                                <Form.Check
                                                    inline
                                                    label="Email"
                                                    name="radiob"
                                                    type="radio"
                                                    id={`inline-radio-2`}
                                                    checked={showSignin === 'Email'} value="Email" onChange={(e) =>handleChangeEmail(e)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Mobile Number"
                                                    name="radiob"
                                                    type="radio"
                                                    id={`inline-radio-3`}
                                                    value="Mobile" checked={showSignin === 'Mobile'}  onChange={(e) =>handleChangeEmail(e)}
                                                />
                                            </div> */}
                                            </Col>
                                            {showSignin == "Mobile" &&
                                                <>
                                                    <Col sm={6} md={6}>
                                                        <div class="form-group">
                                                            <label>Your Name <span style={{ color: "red" }}>*</span></label>
                                                            <input class="form-control"
                                                                type="text"
                                                                placeholder="First Name *"
                                                                name="firstName"
                                                                id='firstName'
                                                                onChange={(e) => HandleChange(e)} />
                                                            {
                                                                errors?.firstName && <p className="form-error">{errors?.firstName}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={6}>
                                                        <div class="form-group">
                                                            <label></label>
                                                            <input class="form-control"
                                                                type="text"
                                                                placeholder="Last Name *"
                                                                id='lastName'
                                                                name="lastName"
                                                                onChange={(e) => HandleChange(e)} />
                                                            {
                                                                errors?.lastName && <p className="form-error">{errors?.lastName}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <div class="form-group">
                                                            <label>Mobile Number</label>
                                                            <PhoneInput
                                                                defaultCountry="QA"
                                                                international
                                                                id='phoneNo'
                                                                name="phoneNo"
                                                                onBlur={(e) => HandleBlur(e)}
                                                                onChange={(value) => HandleChangePhone(value, 'phoneNo')}
                                                                class="form-control"
                                                                placeholder="Mobile Number"
                                                                enableSearch
                                                                disableSearchIcon

                                                            />

                                                            {
                                                                errors?.phoneNo && <p className="form-error">{errors?.phoneNo}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <div class="form-group">
                                                            <label>Email</label>
                                                            <input class="form-control"
                                                                type="email"
                                                                placeholder="Email"
                                                                id='email'
                                                                name="email"
                                                                onChange={(e) => HandleChange(e)} />
                                                            {
                                                                errors?.email && <p className="form-error">{errors?.email}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p>To confirm your phone number, we will send you a text message containing a temporary code. Standard message and data rates may be applicable.</p>
                                                    </Col>
                                                    <Col md={12}>
                                                        <button className="btn submit-btn" onClick={signUpWithPhoneno}><a href="/otp-verification">Verify Mobile Number</a></button>
                                                    </Col>
                                                </>
                                            }
                                            {showSignin == "Email" &&
                                                <>
                                                    <Col sm={6} md={6}>
                                                        <div class="form-group">
                                                            <label>Your Name <span style={{ color: "red" }}>*</span></label>
                                                            <input class="form-control"
                                                                type="text"
                                                                placeholder="First Name *"
                                                                name="firstName"
                                                                id='firstName'
                                                                onBlur={(e) => HandleBlur(e)}
                                                                onChange={(e) => HandleChange(e)} />
                                                            {
                                                                errors?.firstName && <p className="form-error">{errors?.firstName}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={6}>
                                                        <div class="form-group">
                                                            <label></label>
                                                            <input class="form-control"
                                                                type="text"
                                                                placeholder="Last Name *"
                                                                id='lastName'
                                                                name="lastName"
                                                                onBlur={(e) => HandleBlur(e)}
                                                                onChange={(e) => HandleChange(e)} />
                                                            {
                                                                errors?.lastName && <p className="form-error">{errors?.lastName}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <div class="form-group">
                                                            <label>Mobile Number (optional)</label>
                                                            <PhoneInput
                                                                defaultCountry="QA"
                                                                international
                                                                initialValueFormat="national"
                                                                id='phoneNo'
                                                                name="phoneNo"
                                                                onChange={(value) => HandleChangePhone(value, 'phoneNo')}
                                                                countryCallingCodeEditable={false}
                                                                class="form-control"
                                                                placeholder="Mobile Number"
                                                                enableSearch
                                                                disableSearchIcon
                                                                menuPlacement="bottom"
                                                            />
                                                            {
                                                                errors?.phoneNo && <p className="form-error">{errors?.phoneNo}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <div class="form-group">
                                                            <label>Email <span style={{ color: "red" }}>*</span></label>
                                                            <input class="form-control"
                                                                type="email"
                                                                placeholder="Enter"
                                                                id='email'
                                                                name="email"
                                                                value={values.email}
                                                                autocomplete="off"
                                                                onBlur={(e) => HandleBlur(e)}
                                                                onChange={(e) => HandleChange(e)} />
                                                            {
                                                                errors?.email && <p className="form-error">{errors?.email}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <div class="form-group">
                                                            <label>Password <span style={{ color: "red" }}>*</span></label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type={passwordShown ? "text" : "password"}
                                                                    placeholder="At least 8 characters"
                                                                    id='password'
                                                                    name="password"
                                                                    autocomplete="off"
                                                                    onBlur={(e) => HandleBlur(e)}
                                                                    onChange={(e) => HandleChange(e)} style={{ borderRight: 0 }} />
                                                                <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                                                    <i className={`fa ${passwordShown ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                            {
                                                                errors?.password && <p className="form-error">{errors?.password}</p>
                                                            }

                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p>To confirm your email address, we will send you an email containing a temporary code.</p>
                                                    </Col>
                                                    <Col md={12}>
                                                        <button className="btn submit-btn" type="button" onClick={(e) => signUpWithEmail(e)}>Sign up</button>
                                                    </Col>
                                                </>
                                            }
                                            <Col md={12} className="d-none">
                                                <Row className="social-signin mb-2">
                                                    <Col md={12} className="my-2">
                                                        <p className="text-center">Sign up with social account</p>
                                                    </Col>
                                                    <Col md={12} className="my-2 d-flex justify-content-center align-items-center gap-3 social-signin-col">
                                                        <div className="d-flex me-3 gap-2 align-items-center social-google">
                                                            <img src={GoogleIcon} />
                                                            <p className="text-center">Continue with Google</p>
                                                        </div>
                                                        <div className="d-flex gap-2 align-items-center social-facebook">
                                                            <img src={facebookIcon} />
                                                            <p className="text-center">Continue with Facebook</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-center">By creating an account or logging in, you agree to {logoUrl?.brandName}’s<Link to='/terms-condition' state={{ activeKey: "terms" }} onClick={() => { window.scroll(0, 0); }}>Conditions of Use</Link>and<Link to='/privacy-policy' state={{ activeKey: "privacy" }} onClick={() => { window.scroll(0, 0); }}>Privacy Policy.</Link></p>
                                                <hr />
                                            </Col>
                                            <Col md={12}>
                                                <p className="register-link">Already have an account ?<a href="/login">Sign in</a></p>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div >
            <div className="footer-sec">
                <div className="footer-wrapper">
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default CreateAccount