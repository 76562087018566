import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import useProfile from "../../hooks/useProfile";
import { useNavigate } from 'react-router-dom';
import { GetData, CreateEntry, DeleteEntry } from "../../providers/queryData";
import { resourceconstant, Status } from "../../constants";
import { v4 as uuid } from 'uuid';
import useCart from "../../hooks/useCartPage";
import useUserDetails from '../../hooks/useUserDetails'
import CartNotFound from "../not-found/cartNotFound";
import { FormatSeperateValue, FormatValueDecimal } from "../../utils/validate";
import ShareCartSummery from "./shareCartSummery";
import useLogoImage from "../../hooks/useLogoImage";
import { GetUserDetails } from "../../utils/generalMethod";
import customLogger from "../../common/loggerService";
const ShareCart = ({ products, productDetails, defaultAddress, wishList, deleteIcon, cartList, cartResponseData, HandleSaveToCart, AddToWishList }) => {
    const [logoUrl, setLogoUrl] = useLogoImage();
    const navigate = useNavigate()
    const [shareCartData, setShareCartData] = useState([])
    const [shareCartList, setShareCartList] = useState([])
    const current = new URL(window.location.href);
    const pathSegments = current.pathname.split('/');
    const userID = pathSegments[pathSegments.length - 1];
    const [userDetails, setUserDetails] = useUserDetails();
    const [cartUserDetails, setCartUserDetails] = useState([])
    const [cartUserInfo, setCartUserInfo] = useState({})

    const ShareCartList = () => {
        let filter = {}
        GetData(resourceconstant.LIST_CARTS, filter, setShareCartData)
    };

    useEffect(() => {
        if (shareCartData?.severity === "success") {
            const item = shareCartData?.data?.data?.listCarts?.items
            const filtered = item?.filter(data => data?.userId === userID)
            setShareCartList(filtered)
        }
    }, [shareCartData])

    useEffect(() => {
        ShareCartList()
    }, [])

    useEffect(() => {
        const GetUserProfileDetails = async () => {
            try {
                if (userID) {
                    let data = {
                        id: { eq: `${userID}` }
                    }
                    GetUserDetails(data, setCartUserInfo)
                }
            } catch (error) {
                customLogger.error(error)
            }
        };
        GetUserProfileDetails();
    }, [])

    useEffect(() => {
        if (cartUserInfo?.data?.data?.listUsers?.items[0]) {
            setCartUserDetails(cartUserInfo?.data?.data?.listUsers?.items[0])
        }
    }, [cartUserInfo])

    return (
        <section id='cartPageSec' className="pt-1">
            <Container>
                <Row className='gx-1'>
                    <Col xxl={9} xl={9} lg={9} md={8} sm={12} xs={12} className='product-details-column'>
                        <Card className='mb-1'>
                            <CardHeader>
                                <div className="d-flex align-items-center">
                                    <h1 className="me-4">
                                        Shared Cart ({shareCartList.length} Items)
                                    </h1>
                                </div>
                                <div>
                                    <p>
                                        <span>Shared by:</span> {cartUserDetails?.userName}
                                    </p>
                                </div>
                            </CardHeader>
                        </Card>
                        {shareCartList?.length > 0 ? (
                            shareCartList?.map((cart, index) => {
                                const stock = productDetails?.filter(item => item?.productID === cart?.productID)
                                const stockItem = stock?.filter(item => item?.attrValueID === cart?.attrValueID)
                                const outStock = products?.filter(item => item?.id === cart?.productID)
                                const user = cart?.userID ? cart?.userID : cart?.userId
                                const regularPrice = cart?.price;
                                const salePrice = cart?.salesPrice;
                                const discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;
                                const handleLinkClick = (e) => {
                                    e.preventDefault();
                                    navigate(`/product-detail/${cart.productID || cart.id}/`, {
                                        state: {
                                            values: {
                                                subproduct_id: cart.productID || cart.id,
                                                allProducts: cart.productID || cart.id,
                                                search: cart.productID || cart.id,
                                            },
                                        },
                                    });
                                };
                                return (
                                    <Card key={index} className='mb-1'>
                                        <CardBody>
                                            <Row>
                                                <Col md={12}>
                                                    <div className='table-responsive'>
                                                        <table className='table table-borderless mb-0'>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <img width="100%" src={cart.productImage} alt="product" onClick={handleLinkClick} style={outStock?.[0]?.showWhenZeroStock && !outStock?.[0]?.allowPreOrder || (!outStock?.[0]?.allowPreOrder && (stockItem?.[0]?.stockStatus === Status?.STOCKSTATUSOUT || stockItem?.[0]?.stockQuantity === 0 || stockItem?.length === 0)) ? { opacity: 0.3 } : { cursor: "pointer" }} />
                                                                        <p className="out-stock" style={{ color: '#CA0015', fontWeight: 600 }}>{outStock?.[0]?.showWhenZeroStock && !outStock?.[0]?.allowPreOrder || (!outStock?.[0]?.allowPreOrder && (stockItem?.[0]?.stockStatus === Status?.STOCKSTATUSOUT || stockItem?.[0]?.stockQuantity === 0 || stockItem?.length === 0)) ? Status?.STOCKSTATUSOUT : ''}</p>
                                                                    </td>
                                                                    <td>
                                                                        <h6 onClick={handleLinkClick} style={{ cursor: "pointer" }}>{cart.productName} {cart.attrValue && "-"} {cart.attrName} {cart.attrValue}</h6>
                                                                        <p className="pdt-model" style={{ color: "black", fontSize: "16px", fontWeight: "400", marginBottom: "4px" }}>
                                                                            <span>Brand: </span>{cart?.brandDetail?.brandName}
                                                                            {cart?.model && (
                                                                                <>
                                                                                    <span> | </span>
                                                                                    <span>Model: </span>{cart.model}
                                                                                </>
                                                                            )}
                                                                            {cart?.sku && (
                                                                                <>
                                                                                    <span> | </span>
                                                                                    <span>SKU: </span>{cart.sku}
                                                                                </>
                                                                            )}                                                            </p>
                                                                        <div className='d-flex justify-content-start gap-2 price-details'>
                                                                            <h2>{logoUrl?.currency} {FormatValueDecimal(cart?.salesPrice)}</h2>
                                                                            {discountPercentage > 0 && (
                                                                                <>
                                                                                    <h3>{logoUrl?.currency} {FormatValueDecimal(cart?.price)}</h3>
                                                                                    <h4>{FormatSeperateValue(discountPercentage) + '%'} Off</h4>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <p className='qty-price'><span>Unit Tax: </span>{FormatValueDecimal(cart.taxCharge)} <span>|</span> <span>Total:</span> {FormatValueDecimal(cart?.salesPrice * cart.selectedQuantity)}</p>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <p className='qty-price'><span>Quantity </span>{cart.selectedQuantity}</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                );
                                return null;
                            })
                        ) : (
                            <CartNotFound />
                        )}
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12} className='mb-2'>
                        <ShareCartSummery shareCartItem={shareCartList} />
                        <Card className='pay-with-card mt-2'>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ShareCart