import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import CommentIcon from '../../assets/images/icons/message.png';
import { Link, useLocation } from 'react-router-dom';
import { GetData } from '../../providers/queryData';
import { PolicyContent, resourceconstant, Website } from '../../constants';
import NoContentFound from '../not-found/noContent';
import Spinner from '../../common/spinner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from '../../hooks/useLogoImage';
export default function TermsCondition() {
    const location = useLocation();
    const [policyContent, setPolicyContent] = useState([]);
    const [policy, setPolicy] = useState([])
    const [loading, setLoading] = useState(true)
    const [logoUrl, setLogoUrl] = useLogoImage();

    const GetPolicyContent = () => {
        let filter = {}

        GetData(resourceconstant.POLICY_CONTENTS, filter, setPolicy)
    };

    useEffect(() => {
        if (policy?.severity === "success") {
            const content = policy?.data?.data?.listContents?.items
            const policyCon = content.filter(item => item.title && item.content && item.contentName.startsWith(PolicyContent?.TERMSCONDITION));
            setPolicyContent(policyCon)
            setLoading(false)
        }
    }, [policy])

    useEffect(() => {
        GetPolicyContent()
    }, [])

    return (
        <>
            <HelmetProvider><Helmet>
                <title>
                    {logoUrl?.brandName ? `${logoUrl.brandName} : ${PolicyContent?.TERMSCONDITION}` : Website?.WEBSITE}
                </title>
                <meta name="description" content="Understand the legal agreement between you and JerseyMEP.com. Learn about their terms of service, including usage guidelines and limitations." />
                <link rel="canonical" href="https://jerseymep.com/terms-condition" />
                <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />

                <meta property="og:title" content="Jersey MEP - Terms & Conditions" />
                <meta property="og:description" content="Review JerseyMEP.com's terms of service, including guidelines for usage, legal agreements, and limitations." />
                <meta property="og:url" content="https://jerseymep.com/terms-condition" />
                <meta property="og:type" content="website" />
            </Helmet></HelmetProvider>
            <section id="policy-sec" className='my-3'>
                <Container>
                    <Row className='mt-1'>
                        <Col md={12}>
                            {!loading && policyContent?.length > 0 ? (
                                policyContent.map((item, index) => (
                                    <Card>
                                        <CardHeader>
                                            <h2><img src={CommentIcon} />{item?.title}</h2>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>

                                                <Col md={12}>
                                                    <div className="policy-right">
                                                        <div className='policy-description' dangerouslySetInnerHTML={{ __html: item?.content }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))
                            ) : loading ? <Spinner /> : policyContent?.length === 0 && <NoContentFound />}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
