import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { UpdateOrderItem, GetDropDownMaster, GetProductDetailList, updateProductDetails } from "../utils/generalMethod";
import { showErrorToastr, showSuccessToastr } from "../common/toastrService";
import Stepper from "@keyvaluesystems/react-stepper";
import SuccessIcon from "../assets/images/icons/cancel-success.png";
import useProfile from "../hooks/useProfile";
import { DropDownComponent } from "../utils/dropDownComponet";
import ValidateField from "../utils/useValid";
import {
    FieldNameConstant,
    ValidateName,
} from "../constants/validateMsgConstant";
import useUserDetails from '../hooks/useUserDetails'

export default function CancelOrder({ showOrderDetail, cancelOrderDetail, GetOrdersDetails, setCancelShow, cancelShow }) {
    const [cancelOrderResponse, setCancelOrderResponse] = useState(null)
    const [productDetailUpdate, setProductDetailUpdate] = useState(null)
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [userProfile, setUserProfile] = useProfile()
    const [userDetails, setUserDetails] = useUserDetails();
    const [cancelOrderReason, setCancelOrderReason] = useState([])
    const [cancelOrderDrp, setCancelOrderDrp] = useState([])
    const [cancelReason, setCancelReason] = useState(null);
    const [formError, setFormError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [cancelReasonForm, setCancelReasonForm] = useState({
        reason: "",
        reasonDetail: ""
    })
    const [productStock, setProductStock] = useState(null);
    const [productDetailResponse, setProductDetailResponse] = useState(null);

    useEffect(() => {
        let data = {
            pk: {
                eq: `CANCELREASON#`,
            },
        };
        GetDropDownMaster(data, setCancelOrderReason)

    }, [])

    useEffect(() => {
        if (cancelOrderReason?.severity === "success") {
            setCancelOrderDrp(cancelOrderReason?.data?.data?.listDropDownMasters?.items || []);
        }
    }, [cancelOrderReason]);

    useEffect(() => {
        setCancelReasonForm({
            ...cancelReasonForm,
            reason: cancelReason?.value,
        });
    }, [cancelReason]);

    const validation = (values) => {
        const errors = {};
        errors.reason = ValidateField(values.reason, FieldNameConstant?.CANCELREASON, [
            ValidateName?.REQUIRED
        ]);
        if (cancelReasonForm?.reason?.indexOf("Other") > -1) {
            errors.reasonDetail = ValidateField(values.reasonDetail, FieldNameConstant?.SPECIFY_REASON, [
                ValidateName?.REQUIRED
            ]);
        }
        let error = {};
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined) error[key] = errors[key];
        });
        return error;
    };

    const CancelOrder = (event, item) => {
        event.preventDefault();
        let errors = validation(cancelReasonForm);

        if (Object.keys(errors).length === 0) {
            if (cancelOrderDetail?.productId !== "") {
                let productDetailFilter = {
                    productID: {
                        eq: `${cancelOrderDetail?.productId}`,
                    },
                }
                GetProductDetailList(productDetailFilter, setProductDetailResponse)
            }
        }
        else {
            setFormError(errors);
        }
    }

    useEffect(() => {
        if (productDetailResponse?.severity === "success") {
            const filteredItems = productDetailResponse.data.data.listProductDetails.items.filter((item) => 
                item.attrValueID === cancelOrderDetail?.attrValueID || 
                (!item.attrValueID && !cancelOrderDetail?.attrValueID)
            );
            
            setProductStock(filteredItems);
        }
    }, [productDetailResponse]);

    useEffect(() => {
        if (productStock?.length > 0) {
            let trackingData = [...(cancelOrderDetail?.deliveryTracking || [])];
            
            trackingData.push({
                status: "Cancelled",
                date: new Date(),
                trackingBy: `${cancelOrderDetail?.userName}#Customer`,
                phoneNumber: userDetails?.phoneNumber,
                remarks: cancelReasonForm?.reason?.includes("Other") ? cancelReasonForm?.reasonDetail : cancelReasonForm?.reason
            });

            trackingData.forEach(item => delete item?.__typename);

            let data = {
                pk: cancelOrderDetail?.pk,
                id: cancelOrderDetail?.id,
                createdAt: cancelOrderDetail?.createdAt,
                deliveryStatus: "Cancelled",
                deliveryTracking: trackingData
            };

            UpdateOrderItem(data, setCancelOrderResponse);

            let productDetailData = {
                id: productStock?.[0]?.id,
                pk: productStock?.[0]?.pk,
                productID: productStock?.[0]?.productID,
                stockQuantity: (productStock?.[0]?.stockQuantity || 0) + cancelOrderDetail?.quantity
            };
    
            updateProductDetails(productDetailData, setProductDetailUpdate);
        }
    }, [productStock]);

    useEffect(() => {
        if (cancelOrderResponse?.severity === "success") {
            setCancelSuccess(true);
            setCancelShow(false);
            GetOrdersDetails();
        } else if (cancelOrderResponse?.severity === "error") {
            showErrorToastr("Failed to cancel order");
        }
        return () => {
            setCancelOrderResponse(null);
            setProductDetailUpdate(null);
            setCancelShow(false);
            setProductDetailResponse(null);
            setProductStock(null);
        };
    }, [cancelOrderResponse]);

    const OrderSuccessClose = () => {
        setCancelSuccess(false)
        showOrderDetail(false)
    }

    const HandleFormChange = (e) => {
        const { name, value } = e.target

        setCancelReasonForm({
            ...cancelReasonForm,
            [name]: value,
        });

        // re-run the validation check on the changed field value
        const errors = validation({
            ...cancelReasonForm,
            [name]: value,
        });
        // update the formErrors state
        setFormError({
            ...formError,
            [name]: errors[name],
        });
    }

    return (
        <div>
            {/* Cancel order */}
            <Modal show={cancelShow} onHide={() => setCancelShow(false)} size="sm" className='update-modal' aria-labelledby="contained-modal-title-center" centered>
                <Modal.Header closeButton className='pb-0'>
                    <Modal.Title id="contained-modal-title-center" className='d-flex align-items-center'><span className='info-icon'><i className='fa fa-info'></i></span>Cancel this item</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='order-updates'>
                        <p>Are you sure you really want to cancel this item</p>
                        <div className="form-group mt-4">
                            <h4 className="sub-title">Cancellation Reason <span style={{ color: "#CA0015" }}>*</span></h4>
                            <DropDownComponent
                                placeHolder="Select reason"
                                pageName="cancelorder"
                                dpDownName="reason"
                                stateData={cancelOrderDrp}
                                controlName="reason"
                                handleChange={(e) => {
                                    setCancelReason(e);
                                    setFormError((prev) => ({ ...prev, reason: "" }));
                                }}
                                value={cancelReason}
                            />
                            {formError?.reason && (
                                <p style={{ borderRadius: 0, borderRadius: '0 !important' }} className="form-error">{formError?.reason}</p >
                            )}
                        </div>
                        {cancelReasonForm?.reason?.indexOf("Other") > -1 ? (<div className="form-group mt-2">
                            <textarea className='form-control' name="reasonDetail" value={cancelReasonForm?.reasonDetail} onChange={HandleFormChange} rows={6} placeholder='Specify the reason'></textarea>
                            {formError?.reasonDetail && (
                                <p className="form-error">{formError?.reasonDetail}</p>
                            )}
                        </div>) : ""}
                    </div>
                    <div className='bottom-buttons float-end mt-4 mb-2'>
                        <button type='button' className='btn cancel-btn mb-2 me-2' onClick={() => setCancelShow(false)}>Cancel</button>
                        <button type='button' className='btn shop-btn mb-2' onClick={(event) => { CancelOrder(event, cancelOrderDetail) }}>Proceed</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Cancel order success */}
            <Modal show={cancelSuccess} onHide={OrderSuccessClose} size="sm" className='update-modal' aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="pb-1" closeButton></Modal.Header>
                <Modal.Body className='pt-0 px-3'>
                    <div className='d-flex align-items-center flex-column justify-content-center'>
                        <img src={SuccessIcon} className='img-fluid mb-3' loading='lazy' alt="Success Icon" />
                        <h4 className="sub-title mb-3" style={{ fontSize: "1.1rem" }}>Order Canceled Successfully</h4>
                        <p className='text-center'>Your request to cancel the item has been processed successfully. A refund will be made to your account shortly.</p>
                    </div>
                    <div className='bottom-buttons text-center mt-2 mb-2'>
                        <button type='button' className='btn shop-btn mb-2' onClick={OrderSuccessClose}>Continue</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}