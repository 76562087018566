import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import ProductRating from "../../components/productRating";
import ProductToolTip from "../../components/productToolTip";
import { FormatValueDecimal } from "../../utils/validate";
import useLogoImage from "../../hooks/useLogoImage";
import { Status } from "../../constants";
import { CTooltip } from '@coreui/react';
import ReactGA from 'react-ga4';

const CategoryProductCarousal = ({
  productsList,
  categoryItem,
  handleClickAnalytics
}) => {
  const navigate = useNavigate();
  const [logoUrl, setLogoUrl] = useLogoImage();
  const filteredProducts = productsList?.filter(
    (product) => product?.mainCategoryID === categoryItem?.id
  );

  const CustomDot = ({ active, onClick }) => {
    return (
      <li>
        <button
          className={`custom-dot ${active ? "custom-dot--active" : ""}`}
          onClick={() => onClick()}
        />
      </li>
    );
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <Carousel
        responsive={responsive}
        arrows={true}
        swipeable={true}
        infinite={false}
        partialVisible={false}
      >
        {filteredProducts?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt descending
          .map((data, index) => {
              const HandleImageClick = (e, platform) => {
                e.preventDefault();
                navigate(`/product-detail/${data.id}/`, {
                  state: {
                    values: {
                      subproduct_id: data.id,
                      mainCategoryname: data.mainCategory,
                      subcategoryName: data.subCategory,
                      mainCategoryId: data.mainCategoryID,
                      subCategoryId: data.subCategoryID,
                      search: data.id,
                    },
                  },
                });
                ReactGA.event({
                  category: 'Mep Products',
                  action: 'Click',
                  label: platform,
              });
                window.scroll(0, 0);
           };

              return (
                <div>
                  <div
                    className="carousel__item mb-3"
                    key={index}
                    onClick={(e) => HandleImageClick(e, data?.productName)}
                  >
                    {" "}
                    {/* Apply margin bottom for spacing */}
                    <div className="image-box">
                      <img
                        width="100%"
                        src={data?.featuredImage?.url}
                        alt="product"
                        loading="lazy"
                      />
                    </div>
                    <div className="carousel__caption">
                      <p className="title">
                        <a href={`/product-detail/${data.id}/`} onClick={(e) => handleClickAnalytics(e, data?.productName)}>
                          <ProductToolTip productName={data?.productName} />
                        </a>
                      </p>
                      <p className="model" title={data.model}>
                        Brand: <CTooltip content={data?.brandName} placement="bottom">
                          <span>{data?.brandName}</span>
                        </CTooltip>
                      </p>
                      <ProductRating productid={data?.id} products={productsList} />
                      <h4 className="price">
                      {logoUrl?.currency} {FormatValueDecimal(data?.displaySalePrice || 0)}
                      </h4>
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </Carousel>
    </>
  );
};

export default CategoryProductCarousal;
