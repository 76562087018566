import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ProductToolTip from './productToolTip';
import { FormatValueDecimal } from '../utils/validate';
import useLogoImage from '../hooks/useLogoImage';
import SkeletonMaskLatestProducts from '../common/skeletonMaskLatestProducts';
import BestSellersSkeleton from '../common/BestSellersSkeleton';
import { CTooltip } from '@coreui/react';
import ReactGA from 'react-ga4';
export default function CategoryBestSellers({ handleClickAnalytics, catLoading, products, bestSeller, category }) {
    const [logoUrl, setLogoUrl] = useLogoImage();
    const navigate = useNavigate()
    return (
        <Row>
            {!catLoading && bestSeller?.length > 0 ?
                bestSeller?.slice(0, 4)?.map((product, index) => {
                    const matchingDetails = products?.filter(details => details.id === product.id);
                    const HandleImageClick = (e, platform) => {
                        e.preventDefault();
                        navigate(`/product-detail/${matchingDetails[0]?.id}/`, {
                            state: {
                                values: {
                                    subproduct_id: matchingDetails[0]?.id,
                                    mainCategoryname: matchingDetails[0]?.mainCategory,
                                    subcategoryName: matchingDetails[0]?.subCategory,
                                    mainCategoryId: matchingDetails[0]?.mainCategoryID,
                                    subCategoryId: matchingDetails[0]?.subCategoryID,
                                    search: matchingDetails[0]?.id,
                                },
                            },
                        });
                        ReactGA.event({
                            category: 'Mep Products',
                            action: 'Click',
                            label: platform,
                        });
                        window.scroll(0, 0);
                    };
                    return (
                        <Col md={6} className='col-6 px-1 product-col'>
                            <div className='carousel__item'>
                                <div className='image-box'>
                                    <img width="100%" onClick={(e) => HandleImageClick(e, matchingDetails[0]?.productName)} src={product.featuredImage?.url ? product.featuredImage?.url : product.featuredImage} alt="product" loading='lazy' />
                                </div>
                                <div className='carousel__caption'>
                                    <a href={`/product-detail/${matchingDetails[0]?.id}/`} onClick={(e) => handleClickAnalytics(e, matchingDetails[0]?.productName)}>
                                        <p className='title' onClick={HandleImageClick}><span> <ProductToolTip productName={product?.productName} /></span></p>
                                    </a>
                                    <p>Brand : <CTooltip content={product.brandName} placement="bottom">
                                        <span>{product.brandName}</span>
                                    </CTooltip></p>
                                    <h4 class="price">{logoUrl?.currency} {FormatValueDecimal(product?.displaySalePrice)}</h4>
                                </div>
                            </div>
                        </Col>
                    )
                })
                : catLoading && <><><BestSellersSkeleton /><BestSellersSkeleton /><BestSellersSkeleton /><BestSellersSkeleton /></></>
            }
        </Row>
    )
}