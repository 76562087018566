import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ProductToolTip from './productToolTip';
import SkeletonMaskLatestProducts from '../common/skeletonMaskLatestProducts';
import { CTooltip } from '@coreui/react';
import ReactGA from 'react-ga4';


export default function NewArrivals({ handleClickAnalytics, newArrivalLoading, newArrivals }) {
    const navigate = useNavigate()
    return (
        <Row className='product-view'>{newArrivalLoading ? <><SkeletonMaskLatestProducts /><SkeletonMaskLatestProducts /><SkeletonMaskLatestProducts /><SkeletonMaskLatestProducts /></>
            : newArrivals?.slice(0, 4)?.map((arrivals, index) => {
                const HandleImageClick = (e, platform) => {
                    e.preventDefault();
                    navigate(`/product-detail/${arrivals.id}/`, {
                        state: {
                            values: {
                                subproduct_id: arrivals.id,
                                mainCategoryname: arrivals.mainCategory,
                                subcategoryName: arrivals.subCategory,
                                mainCategoryId: arrivals.mainCategoryID,
                                subCategoryId: arrivals.subCategoryID,
                                search: arrivals.id,
                            },
                        },
                    });
                    ReactGA.event({
                        category: 'Mep Products',
                        action: 'Click',
                        label: platform,
                    });
                    window.scroll(0, 0);
                };
                return (
                    <Col md={6} className='col-6 px-1' key={index}>
                        <div className='carousel__item'>
                            <div className='image-box'>
                                <img width="100%" onClick={(e) => HandleImageClick(e, arrivals?.productName)} src={arrivals?.featuredImage?.url} alt="" loading='lazy' />
                            </div>
                            <div className='carousel__caption'>
                                <a href={`/product-detail/${arrivals.id}/`} onClick={(e) => handleClickAnalytics(e, arrivals?.productName)}>
                                    <p className='title' onClick={HandleImageClick}><ProductToolTip productName={arrivals?.productName} /></p>
                                </a>
                                <p>Brand : <CTooltip content={arrivals.brandName} placement="bottom">
                                    <span>{arrivals.brandName}</span>
                                </CTooltip></p>
                            </div>
                        </div>
                    </Col>
                )
            })}
        </Row>
    )
}