import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import customLogger from "../../common/loggerService";
import LocationIcon from '../../assets/images/icons/location.png';
import Amplify, { Auth } from 'aws-amplify';
import { DeleteCart, MoveToWishList } from "../../utils/generalMethod";
import useProfile from "../../hooks/useProfile";
import { useNavigate } from 'react-router-dom';
import { GetData, CreateEntry, DeleteEntry } from "../../providers/queryData";
import { resourceconstant, Status } from "../../constants";
import { showSuccessToastr, showErrorToastr } from "../../common/toastrService";
import { usePreviousPage } from "../../context/usePreviousPage";
import { v4 as uuid } from 'uuid';
import useCart from "../../hooks/useCartPage";
import ShareShow from '../cart/shareShow';
import phoneIcon from '../../assets/images/icons/phone_icon.svg'
import DeleteIcon from "../../assets/images/icons/delete.png";
import CartIcon from "../../assets/images/icons/cart-icon.png";
import storeIcon from "../../assets/images/icons/store-icon.svg"
import useUserDetails from '../../hooks/useUserDetails'
import CartNotFound from "../not-found/cartNotFound";
import { FormatSeperateValue, FormatValueDecimal } from "../../utils/validate";
import Share from "../../assets/images/icons/share-2.svg"
import ShareUserCart from "./shareUserCart";
import useLogoImage from "../../hooks/useLogoImage";
import Spinner from "../../common/spinner";
const CartGridList = ({ setQuantity, quantity, setCartList, setQtyChanges, qtyChanges, setLoading, loading, products, productDetails, defaultAddress, wishList, deleteIcon, cartList, cartResponseData, HandleSaveToCart, AddToWishList }) => {
    const [logoUrl, setLogoUrl] = useLogoImage();
    const navigate = useNavigate()
    const [userProfile, setUserProfile] = useProfile()
    const [userDetails, setUserDetails] = useUserDetails();
    const [cartPageItem, setCartPageItem] = useCart()
    const [productList, setProductList] = useState([])
    const [cartDelete, setCartDelete] = useState([])
    const [deleteItem, setDeleteItem] = useState(null)
    const userid = userProfile?.attributes?.sub;
    const [deleteResponse, setDeleteResponse] = useState([])
    const [shareShow, setShareShow] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductName, setSelectedProductName] = useState(null);
    const [userCart, setUserCart] = useState([])
    const [shareCartShow, setShareCartShow] = useState(false)
    const item = localStorage.getItem('cartData');
    const cartCount = item ? JSON.parse(item) : [];
    const userCartList = useRef([]);
    const [deleteProduct, setDeleteProduct] = useState(null);
    const [wareHouse, setWareHouse] = useState([])
    const [listWareHouse, setListWareHouse] = useState([])

    useEffect(() => {
        userCartList.current = cartList.filter(cart => cart?.userId === userid);
    }, [cartList, userid]);

    const ProductList = (cartItemId) => {
        let filter = {
            id: {
                eq: cartItemId.productID,
            },
        }
        GetData(resourceconstant.PRODUCT_LIST, filter, setProductList)
    };

    const handleShareShow = (productID, productname) => {
        const baseURL = `${window.location.protocol}//${window.location.host}`;
        setSelectedProductId(`${baseURL}/product-detail/${productID}/`);
        setSelectedProductName(productname)
        setShareShow(true);
    };

    const HandleShareCart = () => {
        const baseURL = `${window.location.protocol}//${window.location.host}`;
        setSelectedProductId(`${baseURL}/shareCart/${userid}`);
        setSelectedProductName("ShareCart")
        setShareCartShow(true);
    };

    const HandleDeleteCart = async (productID, productInCart) => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const userid = currentUser.attributes.sub;
            const params = `userId=${userid}&productID=${productID}&attrValueID=${productInCart?.attrValueID ? productInCart?.attrValueID : ""}`;
            setDeleteProduct({ productID, productInCart });

            await DeleteCart(params, true, setCartDelete);

            removeFromLocalStorage(productID, productInCart?.attrValueID);
            const updatedCartList = cartList.filter(
                item => item.productID !== productID || (productInCart?.attrValueID && item.attrValueID !== productInCart.attrValueID)
            );
            setCartList(updatedCartList);

        } catch (err) {
            customLogger.error(err);
        }
    };

    const HandleDelete = async (productID, attValue) => {
        const productInCart = cartList.find(item => item.productID === productID && item?.userId === userid && (!attValue || item.attrValueID === attValue));
        if (productInCart && userDetails?.id) {
            setDeleteItem(productID);
            await HandleDeleteCart(productID, productInCart);
        } else {
            removeFromLocalStorage(productID, attValue);
            const updatedListingCart = cartPageItem.filter(item => item.productID !== productID || (attValue && item.attrValueID !== attValue));
            setCartPageItem(updatedListingCart);
        }
    };

    const removeFromLocalStorage = (productID, attValue) => {
        const storedCart = JSON.parse(localStorage.getItem('cartData')) || [];
        const updatedCart = storedCart.filter(item => item.productID !== productID || (attValue && item.attrValueID !== attValue));
        localStorage.setItem('cartData', JSON.stringify(updatedCart));
        localStorage.setItem('cartLength', updatedCart?.length)
    };

    useEffect(() => {
        if (cartDelete?.status === true && deleteProduct) {
            const { productID, productInCart } = deleteProduct;

            const updatedListingCart = cartPageItem.filter(item => item.productID !== productID || (productInCart?.attrValueID && item.attrValueID !== productInCart.attrValueID));
            setCartPageItem(updatedListingCart);

            localStorage.removeItem('couponData');

            setDeleteProduct(null);
        }
    }, [cartDelete, deleteProduct]);

    const updateQuantity = async (cartItemId, newQuantity) => {
        try {
            let existingCartData = localStorage.getItem('cartData');
            existingCartData = existingCartData ? JSON.parse(existingCartData) : [];

            const updatedCartData = cartPageItem.map(item => {
                const isSameItem = cartItemId?.attrValueID
                    ? item.productID === cartItemId.productID && item.attrValueID === cartItemId.attrValueID
                    : item.productID === cartItemId.productID;
                if (isSameItem) {
                    return { ...item, selectedQuantity: newQuantity };
                }
                return item;
            });

            localStorage.setItem('cartData', JSON.stringify(updatedCartData));
            setCartPageItem(updatedCartData);
            setQtyChanges(true)
        } catch (error) {
            customLogger.error(error);
        }
    };

    const decrement = async (cartItemId) => {
        if (cartItemId.selectedQuantity > 1) {
            const newQuantity = cartItemId.selectedQuantity - 1;
            setQuantity(newQuantity);

            await ProductList(cartItemId);
            await updateQuantity(cartItemId, newQuantity);
        }
    };

    const increment = async (cartItemId, stock, allowPreOrder) => {
        if (allowPreOrder || cartItemId.selectedQuantity + 1 <= stock) {
            const newQuantity = cartItemId.selectedQuantity + 1;
            setQuantity(newQuantity);

            await ProductList(cartItemId);
            await updateQuantity(cartItemId, newQuantity);
        }
    };

    useEffect(() => {
        if (cartResponseData?.status === false) {
            showErrorToastr(cartResponseData?.message);
        }
    }, [cartResponseData]);

    useEffect(() => {
        localStorage.setItem('cartLength', cartPageItem?.length)
    }, [cartPageItem, cartList])

    useEffect(() => {
        const item = cartList?.filter(data => data?.userId === userid)
        setUserCart(item)
    }, [cartList])

    const HandleCartDelete = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const userID = currentUser.attributes.sub;
            const token = localStorage.getItem('IdToken');
            let data = {
                userId: userID
            }
            DeleteEntry(resourceconstant.PRODUCT_ADD_TO_CART_DELETE, true, data, setDeleteResponse)

        } catch (error) {
            customLogger.error(error);
        }
    }

    useEffect(() => {
        if (deleteResponse?.status === true) {
            localStorage.removeItem('cartData');
            setCartPageItem([])
            showSuccessToastr(deleteResponse?.message)
        }
    }, [deleteResponse]);

    const ListWareHouse = () => {
        let filter = {}
        GetData(resourceconstant.LIST_WAREHOUSES, filter, setWareHouse)
    };

    useEffect(() => {
        if (wareHouse?.severity === "success" && cartPageItem) {
            setListWareHouse(wareHouse?.data?.data?.listWareHouses?.items)
        }
    }, [wareHouse])

    useEffect(() => {
        ListWareHouse()
    }, [cartPageItem])

    return (
        <Col xxl={9} xl={9} lg={9} md={8} sm={12} xs={12} className='product-details-column'>
            <Card className='mb-1'>
                <CardHeader>
                    <div className="d-flex align-items-center">
                        <h1 className="me-4">
                            Your Cart ({cartPageItem.length} Items)
                        </h1>
                        {userid && defaultAddress?.address?.city && (
                            <button type='button' className='btn'><img src={LocationIcon} /> {defaultAddress?.address?.city}{defaultAddress?.address?.postalCode && ':'}{" "}{defaultAddress?.address?.postalCode}</button>
                        )}
                    </div>
                    {userid && cartCount?.length > 0 && (
                        <div className="d-flex align-items-center">
                            {cartCount?.length === userCartList?.current?.length && !qtyChanges ? (
                                <>
                                    <button type="button" className="delete-cart" onClick={HandleCartDelete}>
                                        <img src={DeleteIcon} />
                                        Delete Cart
                                    </button>
                                    <span style={{ margin: "0 10px", color: "#C0BABA" }}>|</span>
                                </>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className="btn save-cart-btn"
                                        onClick={() => HandleSaveToCart("myCart")}>
                                        <img src={CartIcon} />
                                        Save Cart
                                    </button>
                                    {userCartList?.current?.length > 0 && (
                                        <span style={{ margin: "0 10px", color: "#C0BABA" }}>|</span>
                                    )}
                                </>
                            )}
                            {userCart?.length > 0 && (
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ color: "rgb(4, 160, 198)" }}
                                    onClick={HandleShareCart}>
                                    <img src={Share} />
                                    Share Cart
                                </button>
                            )}
                        </div>
                    )}
                </CardHeader>
            </Card>

            {!loading && cartPageItem?.length > 0 ? (
                cartPageItem?.map((cart, index) => {
                    const stock = productDetails?.filter(item => item?.productID === cart?.productID);
                    const stockItem = cart?.attrValueID ? stock?.filter(item => item?.attrValueID === cart?.attrValueID && item?.productID === cart?.productID) : stock;
                    const outStock = products?.filter(item => item?.id === cart?.productID);
                    const user = cart?.userID ? cart?.userID : cart?.userId;
                    const regularPrice = cart?.price;
                    const salePrice = cart?.salesPrice;
                    const discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;
                    let isProductWishList = wishList?.findIndex((item) => item?.productId === cart?.productID);

                    const handleLinkClick = (e) => {
                        e.preventDefault();
                        navigate(`/product-detail/${cart.productID || cart.id}/`, {
                            state: {
                                values: {
                                    subproduct_id: cart.productID || cart.id,
                                    allProducts: cart.productID || cart.id,
                                    search: cart.productID || cart.id,
                                },
                            },
                        });
                    };

                    const isOutOfStock = !outStock?.[0]?.allowPreOrder && (
                        outStock?.[0]?.showWhenZeroStock && stockItem?.[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS ||
                        stockItem?.[0]?.stockQuantity - stockItem[0]?.reservedStock === 0 ||
                        cart?.selectedQuantity > stockItem?.[0]?.stockQuantity - stockItem[0]?.reservedStock ||
                        stockItem?.length === 0
                    );

                    const wareHouse = listWareHouse?.filter(item => item?.id === cart?.wareHouseId)

                    const pickupAddress1 = [
                        wareHouse[0]?.addressLine1,
                        wareHouse[0]?.addressLine2,
                    ].filter(Boolean).join(", ");

                    const pickupAddress2 = [
                        wareHouse[0]?.city,
                        wareHouse[0]?.state,
                        wareHouse[0]?.country,
                        - wareHouse[0]?.zipCode
                    ].filter(Boolean).join(", ");

                    return (
                        <Card key={index} className='mb-1'>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <div className='table-responsive'>
                                            <table className='table table-borderless mb-0'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img
                                                                width="100%"
                                                                src={cart.productImage}
                                                                alt="product"
                                                                loading="lazy"
                                                                onClick={handleLinkClick}
                                                                style={isOutOfStock ? { opacity: 0.3 } : { cursor: "pointer" }}
                                                            />
                                                            {isOutOfStock && (
                                                                <p className="out-stock" style={{ color: '#CA0015', fontWeight: 600 }}>
                                                                    {Status?.STOCKSTATUSOUT}
                                                                </p>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <h6 onClick={handleLinkClick} style={{ cursor: "pointer" }}>
                                                                {cart.productName} {cart.attrValue && "-"} {cart.attrName} {cart.attrValue}
                                                            </h6>
                                                            <p className="pdt-model" style={{ color: "black", fontSize: "16px", fontWeight: "400", marginBottom: "4px" }}>
                                                                <span>Brand: </span>{cart?.brandDetail?.brandName}
                                                                {cart?.model && (
                                                                    <>
                                                                        <span> | </span>
                                                                        <span>Model: </span>{cart.model}
                                                                    </>
                                                                )}
                                                                {cart?.sku && (
                                                                    <>
                                                                        <span> | </span>
                                                                        <span>SKU: </span>{cart.sku}
                                                                    </>
                                                                )}
                                                            </p>
                                                            <div className='d-flex justify-content-start gap-2 price-details'>
                                                                <h2>{logoUrl?.currency} {FormatValueDecimal(stockItem?.[0]?.salePrice)}</h2>
                                                                {discountPercentage > 0 && (
                                                                    <>
                                                                        <h3>{logoUrl?.currency} {FormatValueDecimal(stockItem?.[0]?.regularPrice)}</h3>
                                                                        <h4>{FormatSeperateValue(Math.round(discountPercentage * 100) / 100) + "%"} Off</h4>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className='qty-price'>
                                                                    <span>Unit Tax: </span>{FormatValueDecimal(cart.taxCharge)} <span>|</span> <span>Total:</span> {FormatValueDecimal(cart?.salesPrice * cart.selectedQuantity)}
                                                                </p>
                                                                <div className="quantity-btns">
                                                                    <h5>Quantity</h5>
                                                                    <div className="d-flex justify-content-start gap-2">
                                                                        <button
                                                                            type="button"
                                                                            className="btn"
                                                                            onClick={() => decrement(cart)}
                                                                            disabled={!outStock[0]?.allowPreOrder && stockItem[0]?.stockQuantity <= 1 || cart.selectedQuantity === 1}
                                                                            style={
                                                                                !outStock[0]?.allowPreOrder && stockItem[0]?.stockQuantity <= 1 || cart.selectedQuantity === 1
                                                                                    ? { border: "none", color: "grey" }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            <i className="fa fa-minus" aria-hidden="true"></i>
                                                                        </button>
                                                                        <input
                                                                        className="form-control count-num"
                                                                        value={cart.selectedQuantity}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            const newValue = inputValue === "" ? '' : parseInt(inputValue, 10);
                                                                            const stockAvailable = stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock;
                                                                            const allowPreOrder = outStock[0]?.allowPreOrder;
                                                                            if (newValue < 0 || isNaN(newValue)) {
                                                                                return;
                                                                              }
                                                                            if (
                                                                            (!allowPreOrder && newValue > stockAvailable) || 
                                                                            (allowPreOrder && newValue.toString().length > 4)
                                                                            ) {
                                                                            return; 
                                                                            }

                                                                            setQuantity(newValue);
                                                                            updateQuantity(cart, newValue);
                                                                        }}
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="btn"
                                                                            onClick={() =>
                                                                                increment(
                                                                                    cart,
                                                                                    stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock,
                                                                                    outStock[0]?.allowPreOrder
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                (!outStock[0]?.allowPreOrder &&
                                                                                    cart.selectedQuantity >=
                                                                                      stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock) ||
                                                                                  cart.selectedQuantity >= 9999
                                                                            }
                                                                            style={
                                                                                (!outStock[0]?.allowPreOrder &&
                                                                                    cart.selectedQuantity >=
                                                                                      stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock) ||
                                                                                  cart.selectedQuantity >= 9999
                                                                                    ? { border: "none", color: "grey" }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div className='d-flex justify-content-start options-btns'>
                                                                    {isProductWishList > -1 ?
                                                                        <span className='btn ps-0'>Already in wishlist</span> :
                                                                        <button type='button' className='btn ps-0' onClick={() => AddToWishList(cart)}>Move to Wishlist</button>
                                                                    }
                                                                    <button type='button' className='btn delete-btn' onClick={() => HandleDelete(cart.productID, cart?.attrValueID)}>Delete</button>
                                                                    <button type='button' className='btn' onClick={() => handleShareShow(cart.productID, cart?.productName)}>Share</button>
                                                                </div>
                                                                <div class="store-location">
                                                                    <button class="btn btn-secondary dropdown-toggle d-flex align-items-center gap-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src={storeIcon} />
                                                                        <p class="mb-0">Store location</p>
                                                                    </button>
                                                                    <ul class="dropdown-menu dropdown-content p-3">
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <h2 class="dropdown-header">Store Location</h2>
                                                                            <button type="button" class="btn-close float-end" aria-label="Close" data-bs-dismiss="dropdown"></button>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="d-flex gap-2 my-2">
                                                                            <div>
                                                                                <img src={LocationIcon} />
                                                                            </div>
                                                                            <div>
                                                                                <h3>
                                                                                    {wareHouse[0]?.name}
                                                                                </h3>
                                                                                <p>{pickupAddress1}</p>
                                                                                <p>{pickupAddress2}</p>
                                                                            </div>
                                                                        </div>
                                                                        {wareHouse[0]?.phoneNumber && (
                                                                        <div className="d-flex gap-2 mb-3">
                                                                            <img src={phoneIcon} />
                                                                            <h3>{wareHouse[0]?.phoneNumber}</h3>
                                                                        </div>
                                                                        )}
                                                                        {wareHouse[0]?.latitude && wareHouse[0]?.longitude && (
                                                                            <a
                                                                                href={`https://www.google.com/maps?q=${wareHouse[0].latitude},${wareHouse[0].longitude}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <button type="button" className="btn">
                                                                                    View on Map
                                                                                </button>
                                                                            </a>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    );
                })
            ) : (
                loading ? <Spinner /> : cartPageItem?.length === 0 && <CartNotFound />
            )}
            <ShareShow cartList={true} productName={selectedProductName} currentUrl={selectedProductId} setShareShow={setShareShow} shareShow={shareShow} />
            <ShareUserCart productName={selectedProductName} currentUrl={selectedProductId} setShareCartShow={setShareCartShow} shareCartShow={shareCartShow} />
        </Col >
    )
}

export default CartGridList
