import React from "react";
import ProductListQuery from "../../../common/ProductListQuery"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from "../../../hooks/useLogoImage";
import { Website } from "../../../constants";

const AllProductList = () => {
  const [logoUrl, setLogoUrl] = useLogoImage();

  return (
    <>
      <HelmetProvider><Helmet>
        <title>
            {logoUrl?.brandName ? `${logoUrl.brandName} : ${Website?.ALLPRODUCTITEM}` : Website?.WEBSITE}
        </title>
        <meta name="description" content="Find top-quality Mechanical, Electrical, and Plumbing (MEP) supplies in Qatar at Jersey MEP. Shop valves, insulation, pumps & more at competitive prices. Fast delivery & exceptional service!" />
        <link rel="canonical" href="https://jerseymep.com/all-products" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="All MEP Products Online in Qatar | Jersey MEP" />
        <meta property="og:description" content="Browse a wide selection of high-quality MEP supplies in Qatar. Shop at Jersey MEP for competitive prices, fast delivery, and excellent service." />
        <meta property="og:url" content="https://jerseymep.com/all-products" />

        <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />
      </Helmet></HelmetProvider>
      <ProductListQuery page="allProducts" allProduct={true} />
    </>
  );
};

export default AllProductList;

