import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import CommentIcon from '../../assets/images/icons/message.png';
import { GetData } from '../../providers/queryData';
import { PolicyContent, resourceconstant, Website } from '../../constants';
import NoContentFound from '../not-found/noContent';
import Spinner from '../../common/spinner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from '../../hooks/useLogoImage';
const PrivacyPolicy = () => {
    const [privacyPolicyContent, setPrivacyPolicyContent] = useState([]);
    const [privacyPolicy, setPrivacyPolicy] = useState([])
    const [loading, setLoading] = useState(true)
    const [logoUrl, setLogoUrl] = useLogoImage();

    const GetPolicyContent = () => {
        let filter = {}
        GetData(resourceconstant.POLICY_CONTENTS, filter, setPrivacyPolicy)
    };

    useEffect(() => {
        if (privacyPolicy?.severity === "success") {
            const content = privacyPolicy?.data?.data?.listContents?.items
            const policyCon = content.filter(item => item.title && item.content && item?.contentName?.startsWith(PolicyContent?.PRIVACYPOLICY));
            setPrivacyPolicyContent(policyCon)
            setLoading(false)
        }
    }, [privacyPolicy])

    useEffect(() => {
        GetPolicyContent()
    }, [])

    return (
        <>
            <HelmetProvider><Helmet>
                <title>
                    {logoUrl?.brandName ? `${logoUrl.brandName} : ${PolicyContent?.PRIVACYPOLICY}` : Website?.WEBSITE}
                </title>
                <meta name="description" content="Learn about JerseyMEP's privacy policy and terms of use. Understand how your information is collected, used, and protected when interacting with our website and services." />
                <link rel="canonical" href="https://jerseymep.com/privacy-policy" />
                <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />

                <meta property="og:title" content="Jersey MEP - Privacy Policy" />
                <meta property="og:description" content="Understand how JerseyMEP collects, uses, and protects your personal information when using our website and services." />
                <meta property="og:url" content="https://jerseymep.com/privacy-policy" />
                <meta property="og:type" content="website" />
            </Helmet></HelmetProvider>
            <section id="policy-sec" className='my-3'>
                <Container>
                    <Row className='mt-1'>
                        <Col md={12}>
                            {!loading && privacyPolicyContent?.length > 0 ? (
                                privacyPolicyContent.map((item, index) => (
                                    <Card>
                                        <CardHeader>
                                            <h2><img src={CommentIcon} />{item?.title}</h2>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="policy-right">
                                                        <div className='policy-description' dangerouslySetInnerHTML={{ __html: item?.content }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))
                            ) : loading ? <Spinner /> : privacyPolicyContent?.length === 0 && <NoContentFound />}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PrivacyPolicy
