import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Pagination,
  FormSelect
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProductDetailCarousel from "../../../components/productDetailCarousel";
import LocationIcon from "../../../assets/images/icons/location.png";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import ProductFeatures from "./productFeatures";
import SimilarProducts from "./similarProducts";
import {
  ProductBuyNow,
  MoveToWishList,
  DeleteFromWishList,
  GetProductList,
  GetProductDetailList,
  HandleAddToCartToLocal,
} from "../../../utils/generalMethod";
import { v4 as uuid } from "uuid";
import { resourceconstant, Status, ProductPageUrl } from "../../../constants";
import { GetData, PostData } from "../../../providers/queryData";
import useProfile from "../../../hooks/useProfile";
import useUserDetails from "../../../hooks/useUserDetails";
import { FormatValueDecimal, FormatSeperateValue, stripHtmlUsingRegex } from "../../../utils/validate";
import useCart from "../../../hooks/useCartPage";
import customLogger from "../../../common/loggerService";
import ProductRating from "../../../components/productRating";
import ProductRatingComp from "./ProductRatingComp"
import ShareShow from "../../cart/shareShow"
import DetailsNotFound from "../../not-found/detailsNotFound";
import Spinner from "../../../common/spinner";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from "../../../hooks/useLogoImage";

const ProductDetail = () => {
  const [userProfile, setUserProfile] = useProfile();
  const [logoUrl, setLogoUrl] = useLogoImage();
  const [userDetails, setUserDetails] = useUserDetails();
  const [cartPageItem, setCartPageItem] = useCart()
  const location = useLocation();
  const navigate = useNavigate();
  const [productDetailList, setProductDetailList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [userWishListData, setUserWishList] = useState(null);
  const [wishList, setWishList] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [productResponse, setProductResponse] = useState(null);
  const [productDetailResponse, setProductDetailResponse] = useState(null);
  const [multiVariant, setMultiVariant] = useState("");
  const [selectedAttrName, setSelectedAttrName] = useState("");
  const [buyNowData, setBuyNowData] = useState(null);
  const [localCartData, setLocalCartData] = useState([]);
  const [dataSuccess, setDataSuccess] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [productVisitListing, setProductVisitListing] = useState(null)
  const [productVisit, setProductVisit] = useState([])
  const [productVisitResponse, setProductVisitResponse] = useState(null)
  const [heartDisabled, setHeartDisabled] = useState(false);
  const [buyNow, setBuyNow] = useState([])
  const [shareShow, setShareShow] = useState(false);
  const [loading, setLoading] = useState(true)
  const [categoryList, setCategoryList] = useState([])
  const [mainCategory, setMainCategory] = useState([])
  const [subCategoryFilter, setSubCategoryFilter] = useState([])
  const [similarProducts, setSimilarProducts] = useState([])

  const userId = userProfile?.attributes?.sub;
  const currentUrl = window.location.href;
  const current = new URL(window.location.href);
  const pathSegments = current.pathname.split('/');
  const productId = pathSegments?.length === 3 ? pathSegments[pathSegments.length - 1] : pathSegments[pathSegments.length - 2];

  const selectedAttrValue = productDetailList.find(
    (data) => data.attrValueID === multiVariant
  );
  const regularPrice = selectedAttrValue
    ? selectedAttrValue?.regularPrice
    : productDetailList[0]?.regularPrice;
  const salePrice = selectedAttrValue
    ? selectedAttrValue?.salePrice
    : productDetailList[0]?.salePrice;
  const discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;

  const HandleMultivariant = (value, name) => {
    setMultiVariant(value);
    setSelectedAttrName(value);
    setQuantity(1)
  };

  const handleShareShow = () => {
    setShareShow(true)
  }

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increment = (stock) => {
    if (!productList[0]?.allowPreOrder && stock < quantity + 1) return
    setQuantity(quantity + 1);
  };

  useEffect(() => {
    setQuantity(1)
  }, [productList, productDetailList])

  const AddToCart = () => {
    const produid = productId
    let productFilter = {
      id: {
        eq: `${productId}`,
      },
      status: {
        eq: "published"
      }
    };
    if (produid) {
      GetProductList(productFilter, setProductResponse);
    }

    let productDetailFilter = {
      productID: {
        eq: `${productId}`,
      },
    };
    if (produid) {
      GetProductDetailList(productDetailFilter, setProductDetailResponse);
    }
  };

  const GetCategoryList = () => {
    let filter = {

    }
    GetData(resourceconstant.LIST_CATEGORY, filter, setCategoryList)
  };

  useEffect(() => {
    GetCategoryList()
  }, [])

  useEffect(() => {
    if (categoryList?.severity === "success") {
      const item = categoryList?.data?.data?.listCategories?.items
      const filtered = item.find(data => data?.id === productList[0]?.mainCategoryID && data?.pk === "MAIN#")
      const subFiltered = item.filter(data => data?.pk.startsWith("SUB#"));
      const subCategory = subFiltered.find(data => data?.id === productList[0]?.subCategoryID)
      setSubCategoryFilter(subCategory)
      setMainCategory(filtered)
    }
  }, [categoryList, productList[0]])

  const handleLinkClick = (e) => {
    e.preventDefault();
    navigate(`/product-category/${decodeURIComponent(mainCategory?.slug?.toLowerCase()).replace(/ /g, '-')}/`, {
      state: {
        values: {
          category_id: `${productList[0]?.mainCategoryID}`,
          search: `${productList[0]?.mainCategoryID}`,
        },
      },
      search: `?category_id=${productList[0]?.mainCategoryID}`,
    });
  };

  const handleLinkSubClick = (e) => {
    e.preventDefault();
    navigate(
      `/product-category/${decodeURIComponent(mainCategory?.slug?.toLowerCase())}/${decodeURIComponent(
        subCategoryFilter?.slug
      ).replace(/ /g, "-")}/`,
      {
        state: {
          values: {
            subCategoryId: `${productList[0]?.subCategoryID}`,
            mainCategoryname: `${productList[0]?.mainCategory}`,
            mainCategoryId: `${productList[0]?.mainCategoryID}`,
            subCategoryId: `${productList[0]?.subCategoryID}`,
            subcategoryName: `${productList[0]?.subCategory}`,
            search: `${productList[0]?.subCategoryID}`,
          },
        },
        search: `?subCategoryId=${productList[0]?.subCategoryID}`,
      }
    );
  };

  const HandleAddToCart = async () => {
    const userId = userProfile?.attributes?.sub;
    const userEmail = userProfile?.attributes?.email;
    const mobileNo = userDetails?.phoneNumber;
    if(!quantity){
      setQuantity(1)
    }
    else{
      setQuantity(quantity)
    }
    HandleAddToCartToLocal(
      productList[0],
      productDetailList[0],
      userId,
      userEmail,
      mobileNo,
      quantity,
      setLocalCartData,
      setDataSuccess,
      selectedAttrValue,
      setCartPageItem
    );
  }; 

  useEffect(() => {
    if (buyNowData?.severity === "success") {
      setBuyNow(buyNowData)
    }
  }, [buyNowData]);

  useEffect(() => {
    if (buyNow?.severity === "success") {
      localStorage.setItem('checkoutDirect', true)
      navigate("/checkout", {
        state: {
          checkoutData: buyNow,
        },
      });
      localStorage.setItem('buyNow', JSON.stringify(buyNow?.data[0]))
    }
  }, [buyNow]);

  useEffect(() => {
    const item = buyNow?.data ? JSON.stringify(buyNow?.data[0]) : []
    localStorage.setItem('buyNow', item)
  }, [])

  const HandleBuyNow = async () => {
    const userId = userProfile?.attributes?.sub;
    const productId = productList[0]?.id;
    const regular = selectedAttrValue?.attrValueID ? selectedAttrValue?.regularPrice : productDetailList[0]?.regularPrice
    const sales = selectedAttrValue?.attrValueID ? selectedAttrValue?.salePrice : productDetailList[0]?.salePrice
    const price = regular
    const salesPrice = sales
    const tax = productList[0]?.taxDetail?.reduce(
      (total, item) => total + (item?.taxCharge * sales / 100),
      0
    );
    let data = {
      productID: productId,
      userId: userId,
      quantity: quantity,
      price: price,
      salesPrice: salesPrice,
      attrName: selectedAttrValue?.attrValueID ? selectedAttrValue?.attrName : '',
      attrValueID: selectedAttrValue?.attrValueID ? selectedAttrValue?.attrValueID : '',
      attrValue: selectedAttrValue?.attrValue ? selectedAttrValue?.attrValue : '',
      taxCharge: tax ? `${tax}` : '0',
      productImage: productList[0]?.thumbnailUrl ? productList[0]?.thumbnailUrl : productList[0]?.featuredImage?.url,
      model: selectedAttrValue?.attrValueID ? selectedAttrValue.model : productDetailList[0]?.model,
      sku: selectedAttrValue?.attrValueID ? selectedAttrValue.sku : productDetailList[0]?.sku,
      mainCategory: productList[0]?.mainCategory,
      subCategoryN: productList[0]?.subCategory,
      stockUnit: selectedAttrValue?.attrValueID ? selectedAttrValue.stockUnit : productDetailList[0]?.stockUnit,
      itemsPerUnit: selectedAttrValue?.attrValueID ? selectedAttrValue.itemsPerUnit : productDetailList[0]?.itemsPerUnit,
      currentStock: selectedAttrValue?.attrValueID ? selectedAttrValue.stockQuantity - selectedAttrValue?.reservedStock : productDetailList[0]?.stockQuantity - productDetailList[0]?.reservedStock,
      mainCategoryId: productList[0]?.mainCategoryID,
      subCategoryId: productList[0]?.subCategoryID,
      wareHouseName: selectedAttrValue ? selectedAttrValue?.storageInfo?.wareHouse : productDetailList[0]?.storageInfo?.wareHouse,
      wareHouseId: selectedAttrValue ? selectedAttrValue?.storageInfo?.wareHouseId : productDetailList[0]?.storageInfo?.wareHouseId,
    }
    ProductBuyNow(data, true, setBuyNowData)
  }

  const redirectToLogin = () => {
    navigate('/login');
  };

  const AddToWishList = async () => {
    setHeartDisabled(true)
    const userid = userProfile?.attributes?.sub;
    const currentDate = new Date().toISOString();

    const price = productList[0]?.attrValue
      ?.map((item) => {
        const matchingDetail = productDetailList.find(
          (data) => data.attrValueID === item.attrValueID
        );
        return matchingDetail;
      })
      .filter((detail) => detail !== undefined);

    const checked = price ? price : productDetailList
    const priceCondition = price?.[0] ? price?.[0] : productDetailList[0]

    const lowestPriceDetail = checked.reduce((lowest, current) => {
      const currentSalePrice = current?.salePrice || current?.regularPrice;
      const lowestSalePrice = lowest?.salePrice || lowest?.regularPrice;

      return currentSalePrice < lowestSalePrice ? current : lowest;
    }, priceCondition);

    const wishlist = lowestPriceDetail ? lowestPriceDetail : productDetailList[0]

    let wishListData = {
      pk: "WL#",
      id: uuid(),
      userId: userid,
      productId: productList[0]?.id,
      productName: productList[0]?.productName,
      productImage: productList[0]?.featuredImage?.url,
      mainCategory: productList[0]?.mainCategory,
      mainCategoryId: productList[0]?.mainCategoryID,
      subCategory: productList[0]?.subCategory,
      subCategoryId: productList[0]?.subCategoryID,
      brandName: productList[0]?.brandName,
      brandId: productList[0]?.brandID,
      model: wishlist?.model,
      rating: wishlist?.rating,
      regularPrice: wishlist?.regularPrice,
      salePrice: wishlist?.salePrice,
      createdAt: currentDate,
    };
    MoveToWishList(wishListData, setResponseData);
  };

  const DeleteWishList = async () => {
    setHeartDisabled(true)
    const data = {
      pk: "WL#",
      id: wishList[0]?.id,
    };

    DeleteFromWishList(data, setDeleteResponse);
  };

  useEffect(() => {
    if (productResponse?.severity === "success") {
      const item = productResponse?.data?.data?.listProducts?.items
      if (item?.length > 0) {
        setProductList(productResponse?.data?.data?.listProducts?.items);
      }
    }
  }, [productResponse]);

  useEffect(() => {
    if (productDetailResponse?.severity === "success" && productList) {
      const data = productDetailResponse?.data?.data?.listProductDetails?.items
      const filteredProductsDetails = data?.filter(product =>
        productList?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
          (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
      );
      setProductDetailList(
        filteredProductsDetails

      );
    }
  }, [productDetailResponse, productList]);

  useEffect(() => {
    if (productDetailResponse?.severity === "success") setLoading(false)
  }, [productDetailList])

  useEffect(()=>{
    console.log('loading',loading)
  },[loading])

  useEffect(() => {
    AddToCart();
  }, [location?.state?.values]);

  useEffect(() => {
    if (responseData?.severity === "success") {
      showSuccessToastr("Added to wishlist");
      GetWishList();
      setResponseData(null);
      setHeartDisabled(false);
    }
  }, [responseData]);

  const GetWishList = async () => {
    const userid = userProfile?.attributes?.sub;
    let filter = {
      userId: { eq: `${userid}` },
      productId: { eq: `${productList[0]?.id}` },
    };
    GetData(resourceconstant.LIST_WISH_LISTS, filter, setUserWishList);
  };

  useEffect(() => {
    if (productList[0]?.id != "")
      GetWishList();
  }, [productList[0]?.id]);

  useEffect(() => {
    if (userWishListData?.severity === "success") {
      setWishList(userWishListData?.data?.data?.listWishLists?.items);
    }
  }, [userWishListData]);

  useEffect(() => {
    if (deleteResponse?.severity === "success") {
      showSuccessToastr("Product removed from wishlist");
      GetWishList();
      setDeleteResponse(null);
      setHeartDisabled(false);
    }
  }, [deleteResponse]);

  useEffect(() => {
    let multivar = productList[0]?.attrValue?.find((item) => {
      return productDetailList.some(data =>
        data.attrValueID === item.attrValueID
      );
    });

    if (multivar) {
      const matchingDetail = productDetailList.find(data =>
        data.attrValueID === multivar.attrValueID
      );
      setMultiVariant(matchingDetail?.attrValueID);
      setSelectedAttrName(multivar?.attrValueID);
    }
  }, [productList, productDetailList]);

  useEffect(() => {
    let userData = userDetails?.addressDetails?.filter(
      (item) => item.default === true
    );
    if (userData?.length > 0) setDefaultAddress(userData[0]);
  }, [userDetails]);

  const ProductVisitList = async () => {
    let filter = {
    }
    GetData(resourceconstant.PRODUCT_VISIT_COUNT_LIST, filter, setProductVisitListing)
  };
  const hasUpdatedProductVisit = useRef(false);
  const hasProductVisist = useRef(false);

  useEffect(() => {
    ProductVisitList()
  }, [])

  useEffect(() => {
    if (productVisitListing?.severity === "success") {
      setProductVisit(productVisitListing?.data?.data?.listProductVisits?.items)
      hasProductVisist.current = true;
    }
  }, [productVisitListing])

  const fetchLocationByCoordinates = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
      fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.error || !data.address) {
            customLogger.error(data.error)
          } else {
            resolve({
              latitude,
              longitude,
              city: data.display_name,
              country: data.address.country,
              source: 'coordinates'
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const fetchLocationByIP = () => {
    return new Promise((resolve, reject) => {
      fetch('https://ipapi.co/json/')
        .then(response => {
          return response.json();
        })
        .then(data => {
          resolve({
            latitude: data.latitude,
            longitude: data.longitude,
            city: data.display_name,
            country: data.country,
            source: 'ip'
          });
        })
        .catch(error => {
          reject(new Error(error));
        });
    });
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchLocationByCoordinates(latitude, longitude)
              .then(data => resolve(data))
              .catch(err => {
                fetchLocationByIP()
                  .then(data => resolve(data))
                  .catch(err => reject(err));
              });
          },
          (error) => {
            fetchLocationByIP()
              .then(data => resolve(data))
              .catch(err => reject(err));
          }
        );
      } else {
        fetchLocationByIP()
          .then(data => resolve(data))
          .catch(err => reject(err));
      }
    });
  };

  const cleanVisitedInfo = (visitedInfoArray) => {
    return visitedInfoArray?.map(info => {
      const { __typename, ...cleanedInfo } = info;
      return cleanedInfo;
    });
  };

  const UpdateProductVisit = async () => {
    try {
      const locationObj = await getLocation();
      const location = `${locationObj.latitude},${locationObj.longitude}`;
      const city = locationObj.city;
      const currentDateTime = new Date().toISOString();
      const currentTime = new Date().toLocaleTimeString();
      const currentDate = new Date().toLocaleDateString();
      const userId = userProfile?.attributes?.sub;
      const produID = productList[0]?.id;
      const name = userDetails?.userName
      const localName = localStorage.getItem('fullName')
      const userName = name
      let existingProductVisit = [];

      if (productVisit?.length > 0) {
        existingProductVisit = productVisit.filter(visit => {
          const visitDate = new Date(visit.visitedAt).toLocaleDateString();
          if (userId) {
            return visit.prodId === produID && visit.userId === userId && visitDate === currentDate;
          }
          else {
            return visit.prodId === produID && visitDate === currentDate && !visit.userId;
          }
        });
      }

      if (existingProductVisit.length > 0) {
        const updatedVisitedInfo = [
          ...cleanVisitedInfo(existingProductVisit[0].visitedInfo),
          {
            location: city,
            time: currentTime,
            platform: "Web"
          }
        ];

        const data = {
          pk: existingProductVisit[0]?.pk,
          id: existingProductVisit[0]?.id,
          prodId: produID,
          userId: userId,
          userName: userName && userName,
          visitedAt: currentDateTime,
          visitedInfo: updatedVisitedInfo
        };
        PostData(resourceconstant.PRODUCT_VISIT_COUNT_UPDATE, data, setProductVisitResponse);
      } else {
        const input = {
          pk: "PV#",
          id: uuid(),
          prodId: produID,
          userId: userId,
          userName: userName && userName,
          visitedAt: currentDateTime,
          visitedInfo: [
            {
              location: city,
              time: currentTime,
              platform: "Web"
            }
          ]
        };

        PostData(resourceconstant.PRODUCT_VISIT_COUNT, input, setProductVisitResponse);
      }
    } catch (error) {
      customLogger.error(error);
    }
  };

  useEffect(() => {
    if (userProfile && productList.length > 0 && hasProductVisist.current) {
      UpdateProductVisit();
      hasUpdatedProductVisit.current = true;
    }
  }, [userProfile, productList, hasProductVisist.current]);

  const stockunit = selectedAttrValue ? selectedAttrValue?.stockUnit : productDetailList[0]?.stockUnit
  const stockqty = selectedAttrValue ? selectedAttrValue?.itemsPerUnit : productDetailList[0]?.itemsPerUnit
  return (
    <>
      <HelmetProvider><Helmet>
        <title>{`${productList[0]?.productName ? productList[0]?.productName : logoUrl?.brandName}`}</title>
        <link rel="canonical" href={`${current?.origin}${ProductPageUrl?.PRODUCT_DETAIL_URL}${productList[0]?.id}`} />
        <meta name="description" content={`${productList[0]?.seoDescription}`} />
        <meta name="title" content={`${productList[0]?.productName}`} />
        <meta name="keywords" content={`${productList[0]?.keyWord?.[0]}`} />

        <meta property="og:title" content={`${productList[0]?.productName}`} />
        <meta property="og:description" content={`${productList[0]?.seoDescription}`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${current?.origin}${ProductPageUrl?.PRODUCT_DETAIL_URL}${productList[0]?.id}`} />
        <meta property="og:image" content={`${productList[0]?.featuredImage?.url}`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="twitter:card" content="summary_large_image" /> 
        <meta property="twitter:title" content={`${productList[0]?.productName}`} />
        <meta property="twitter:description" content={`${productList[0]?.seoDescription}`} />
        <meta property="twitter:url" content={`${current?.origin}${ProductPageUrl?.PRODUCT_DETAIL_URL}${productList[0]?.id}`} />
        <meta property="twitter:image" content={`${productList[0]?.featuredImage?.url}`} />
        
        <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />
      </Helmet></HelmetProvider>

      {!loading && productDetailList?.length > 0 && productList?.length > 0 ? (<><div className="product-list-filter mt-1">
        <Container>
          <div className="product-detail mt-1">
            <Row className="mb-3">
              <Col md={12}>
                <div className="bread-crumbs">
                  <nav>
                    {location?.state?.values?.allProducts ? (
                      <>
                        <Link
                          to="/all-products"
                          className="breadcrumb-not-active"
                          state={{ allProducts: true }}
                        >
                          all products
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to={`/product-category/${decodeURIComponent(mainCategory?.slug?.toLowerCase()).replace(/ /g, '-')}/`}
                          onClick={handleLinkClick}
                          className="breadcrumb-not-active"
                        >
                          {productList[0]?.mainCategory}
                        </Link>
                        <span className="breadcrumb-arrow">/</span>
                        <Link
                          to={`/product-category/${decodeURIComponent(mainCategory?.slug?.toLowerCase())}/${decodeURIComponent(
                            subCategoryFilter?.slug
                          ).replace(/ /g, "-")}/`}
                          onClick={handleLinkSubClick}
                          className="breadcrumb-not-active"
                        >
                          {productList[0]?.subCategory}
                        </Link>
                      </>
                    )}
                    <span className="breadcrumb-arrow">/</span>
                    <a className="breadcrumb-active">
                      {productList[0]?.productName}
                      {productDetailList[0]?.model ? ` - ${productDetailList[0]?.model}` : ""}
                    </a>
                  </nav>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <div className="product-detail-left">
                  <ProductDetailCarousel
                    productDetailList={productDetailList}
                    selectedAttrValue={selectedAttrValue}
                    productList={productList}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="product-detail-right">
                  <h1 className="pdt-name">
                    {productList[0]?.productName} {selectedAttrValue && "-"}  <span>{selectedAttrValue
                      ? selectedAttrValue.attrName
                      : productDetailList[0]?.attrName} {selectedAttrValue
                        ? selectedAttrValue.attrValue
                        : productDetailList[0]?.attrValue}</span>
                  </h1>
                  <p className="pdt-model">
                    <span className="ms-0">Brand:</span>{productList[0]?.brandName}<span>|</span><span>SKU:</span>{" "}
                    {selectedAttrValue
                      ? selectedAttrValue.sku
                      : productDetailList[0]?.sku}
                    {(selectedAttrValue?.attrValueID ? selectedAttrValue?.model : productDetailList[0]?.model) && (
                      <>
                        <span>|</span> <span>Model:</span> {selectedAttrValue?.attrValueID ? selectedAttrValue?.model : productDetailList[0]?.model}
                      </>
                    )}
                  </p>
                  <ProductRating productid={productList[0]?.id} products={productList} />
                  <hr />
                  <>
                    <div className="pdt-price">
                      <p className="price">
                        {logoUrl?.currency}{" "}
                        <span>
                          {selectedAttrValue
                            ? FormatValueDecimal(selectedAttrValue?.salePrice)
                            : FormatValueDecimal(
                              productDetailList[0]?.salePrice
                            )}
                        </span>
                      </p>
                      {discountPercentage > 0 && (
                        <>
                          <p className="offer-price">
                            {logoUrl?.currency}{" "}
                            {selectedAttrValue
                              ? FormatValueDecimal(selectedAttrValue?.regularPrice)
                              : FormatValueDecimal(
                                productDetailList[0]?.regularPrice
                              )}
                          </p>
                          <p className="price-off">
                            {FormatSeperateValue(discountPercentage) + "%"} Off
                          </p>
                        </>
                      )}
                    </div>
                    <hr />
                    {productList[0]?.singleVariant !== true && (
                      <div className="product-images">
                        <h3>{productDetailList[0]?.attrName}</h3>
                        <div className="variant-section">
                          <div className="my-2 gap-2 d-flex flex-wrap">
                            {productList[0]?.attrValue?.map((item, index) => {
                              const matchingDetail = productDetailList.find(data =>
                                !productList[0]?.allowPreOrder && !productList[0]?.showWhenZeroStock ? data.attrValueID === item.attrValueID && data.stockStatus === Status?.INSTOCKSTATUS : data.attrValueID === item.attrValueID
                              );
                              if (!matchingDetail) return null;
                              return (
                                <Card
                                  key={index}
                                  className={`resp-btn ${selectedAttrName === item.attrValueID ? "active" : ""}`}
                                  onClick={() => HandleMultivariant(item.attrValueID, item.value)}
                                >
                                  {item.value}<br />
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  <hr />
                  <div className="about-pdt">
                    <h2 className="about-title">About this product</h2>
                    <p className="pdt-description">
                      <div dangerouslySetInnerHTML={{ __html: productList[0]?.productDescription }} />
                    </p>
                  </div>
                  <ProductFeatures
                    productList={productList}
                    productDetailList={productDetailList}
                    multiVariant={multiVariant}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="ship-to-sec">
                  {userId && (<><div className="ship-to flex-wrap">
                    <p>Ship to</p>
                    <div className="d-flex location">
                      <img src={LocationIcon} />
                      <p>
                        {defaultAddress?.address?.city}{defaultAddress?.address?.postalCode && ":"}{" "}
                        {defaultAddress?.address?.postalCode}
                      </p>
                    </div>
                  </div>
                    <hr /></>)}

                  <div
                    className={`${selectedAttrValue?.stockStatus
                      ? selectedAttrValue.stockStatus === Status?.OUTOFSTOCKSTATUS
                        ? "text-danger"
                        : "instock"
                      : productDetailList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                        ? "text-danger"
                        : "instock"} ${productList[0]?.allowPreOrder ? "preorder-style" : ""}`}
                  >
                    <h2 style={{ fontSize: productList[0]?.allowPreOrder ? '13px' : '15px' }}>
                      {productList[0]?.allowPreOrder ? (
                        <>
                          <span>Pre-Order Available </span>
                          {(() => {
                            const stockQuantity = Math.max(
                              selectedAttrValue
                                ? selectedAttrValue?.stockQuantity - selectedAttrValue?.reservedStock
                                : productDetailList[0]?.stockQuantity - productDetailList[0]?.reservedStock,
                              0
                            );

                            const stockStatus = selectedAttrValue
                              ? selectedAttrValue.stockStatus
                              : productDetailList[0]?.stockStatus;

                            const statusText = stockStatus === Status?.INSTOCKSTATUS
                              ? Status?.STOCKSTATUSIN
                              : '';

                            return stockStatus === Status?.INSTOCKSTATUS && `(${stockQuantity} ${statusText})`;
                          })()}
                        </>
                      ) : (
                        <>
                          {(() => {
                            const stockQuantity = Math.max(
                              selectedAttrValue
                                ? selectedAttrValue?.stockQuantity - selectedAttrValue?.reservedStock
                                : productDetailList[0]?.stockQuantity - productDetailList[0]?.reservedStock,
                              0
                            );

                            const stockStatus = selectedAttrValue
                              ? selectedAttrValue.stockStatus
                              : productDetailList[0]?.stockStatus;

                            const statusText = stockStatus === Status?.INSTOCKSTATUS
                              ? Status?.STOCKSTATUSIN
                              : '';

                            return `${stockStatus === Status?.INSTOCKSTATUS ? stockQuantity : ''} ${statusText}`;
                          })()}
                        </>
                      )}
                    </h2>
                  </div>
                  {productList[0]?.allowPreOrder && (
                    <p>
                      Delivery time is up to <span>{productList[0]?.leadTime} days,</span> depending on stock availability
                    </p>)}
                  {stockunit && stockqty > 0 && (
                    <div>
                      <p className="package-type">
                        <span>Package Type:</span> {stockunit} ({stockqty && stockqty} items) </p>
                    </div>
                  )}
                  <div className="quantity d-flex justify-content-between align-items-center">
                    <h2>Quantity</h2>
                  </div>
                  <div className="quantity-action">
                    <a
                      onClick={() => decrement()}
                      disabled={quantity === 1}
                      style={quantity === 1 ? { border: "none", color: "grey" } : {}}
                    >
                      <i className="fa fa-minus" aria-hidden="true"></i>
                    </a>
                    <input
                      className="form-control count-num"
                      value={quantity}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const newValue = inputValue === "" ? '' : parseInt(inputValue, 10);

                        if (newValue < 0 || isNaN(newValue)) {
                          return;
                        }

                        const stock = selectedAttrValue
                          ? selectedAttrValue.stockQuantity - selectedAttrValue.reservedStock
                          : productDetailList[0]?.stockQuantity - productDetailList[0]?.reservedStock;
                        if (
                          (!productList[0]?.allowPreOrder && newValue > stock) ||
                          (productList[0]?.allowPreOrder && inputValue?.length > 4)
                        ) {
                          return;
                        }

                        setQuantity(newValue);
                      }}
                    />
                    <a
                      onClick={() => {
                        const stock =
                          selectedAttrValue
                            ? selectedAttrValue.stockQuantity - selectedAttrValue.reservedStock
                            : productDetailList[0]?.stockQuantity - productDetailList[0]?.reservedStock;

                        if (productList[0]?.allowPreOrder && quantity < 9999 || quantity < stock) {
                          increment();
                        }
                      }}
                      disabled={
                        (!productList[0]?.allowPreOrder &&
                        quantity >=
                        (selectedAttrValue
                          ? selectedAttrValue.stockQuantity - selectedAttrValue.reservedStock
                          : productDetailList[0]?.stockQuantity - productDetailList[0]?.reservedStock)) || quantity >= 9999
                      }
                      style={
                        (!productList[0]?.allowPreOrder &&
                          quantity >=
                          (selectedAttrValue
                            ? selectedAttrValue.stockQuantity - selectedAttrValue.reservedStock
                            : productDetailList[0]?.stockQuantity - productDetailList[0]?.reservedStock)) || quantity >= 9999
                          ? { border: "none", color: "grey" }
                          : {}
                      }
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="add-to btn"
                    onClick={HandleAddToCart}
                    disabled={
                      productList[0]?.allowPreOrder
                        ? false
                        : !productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder
                          ? productList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                          : productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder && selectedAttrValue
                            ? selectedAttrValue?.stockStatus === Status?.OUTOFSTOCKSTATUS
                            : productDetailList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                    }
                  >
                    Add to Cart
                  </button>
                  <button
                    type="submit"
                    className="buy-now btn"
                    onClick={userId ? HandleBuyNow : redirectToLogin}
                    disabled={
                      productList[0]?.allowPreOrder
                        ? false
                        : !productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder
                          ? productList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                          : productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder && selectedAttrValue
                            ? selectedAttrValue?.stockStatus === Status?.OUTOFSTOCKSTATUS
                            : productDetailList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                    }
                  >
                    Buy Now
                  </button>
                  <hr />
                  <div className="share-wishlit">
                    <button type="submit" className="outline-btn btn" onClick={handleShareShow}>
                      <i className="fa fa-share-alt"></i> <span className="share-text">Share</span>
                    </button>
                    {wishList?.length > 0 ? (
                      <button
                        type="submit"
                        className="outline-btn btn"
                        onClick={() => DeleteWishList()}
                        disabled={
                          productList[0]?.allowPreOrder
                            ? false
                            : !productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder
                              ? productList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                              : productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder && selectedAttrValue
                                ? selectedAttrValue?.stockStatus === Status?.OUTOFSTOCKSTATUS
                                : productDetailList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                        }
                        style={{ color: "red" }}
                      >
                        <i className="fa fa-heart-o"></i> <span className="share-text">Wishlist</span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="outline-btn btn"
                        disabled={
                          productList[0]?.allowPreOrder
                            ? false
                            : !productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder
                              ? productList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                              : productList[0]?.showWhenZeroStock && !productList[0]?.allowPreOrder && selectedAttrValue
                                ? selectedAttrValue?.stockStatus === Status?.OUTOFSTOCKSTATUS
                                : productDetailList[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS
                        }
                        onClick={userId ? AddToWishList : redirectToLogin}
                      >
                        <i className="fa fa-heart-o"></i> <span className="share-text">Wishlist</span>
                      </button>
                    )}
                  </div>
                  <hr />
                </div>
              </Col>
            </Row>
          </div>
          <ProductRatingComp productId={productList[0]?.id} />
          <Row className="product-list pt-1">
            <Col md={12}>
              <div className="products-sec mt-0">
                {similarProducts?.length > 0 && (
                  <div className="overlap-title">
                    <h2 className="text-title">Similar Products</h2>
                  </div>
                )}
                <div className="product-carouselItem">
                  <SimilarProducts
                    productList={productList}
                    productDetailList={productDetailList}
                    setSimilarProducts={setSimilarProducts}
                    similarProducts={similarProducts}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
        <ShareShow productName={productList[0]?.productName} currentUrl={currentUrl} setShareShow={setShareShow} shareShow={shareShow} /></>)
        : loading ? <Spinner /> : <DetailsNotFound />}
    </>
  );
};

export default ProductDetail;
