import React, { useState, useEffect } from "react";
import { Col, Container, Card, CardBody, Row, Form, CardHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ReactCaptchaa from 'react-captchaa'
import 'react-captchaa/dist/index.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import companyIcon from '../../../assets/images/icons/company-icon.svg'
import contactus from '../../../assets/images/account/contactus.svg'
import locationIcon from '../../../assets/images/icons/location-icon.svg'
import phoneIcon from '../../../assets/images/icons/phone-icon.svg'
import bulidingIcon from '../../../assets/images/icons/building-icon.svg'
import mailIcon from '../../../assets/images/icons/email-icon.svg'
import webIcon from '../../../assets/images/icons/web-icon.svg'
import business from '../../../assets/images/icons/business.svg'

const ContactForm = ({
  file,
  formError,
  formValues,
  userProfile,
  HandleFormChange,
  HandleChangePhone,
  HandleSendMessage,
  handleFileSelect,
  setCaptchaVerified,
  RemoveFile,
  loading,
  errorFile,
  captchaRef,
  captchaKey,
  HandleBlur,
  HandleBlurPhoneNo,
  logoUrl
}) => {

  return (
    <>
      <HelmetProvider><Helmet>
        <title>
          {logoUrl?.brandName ? `${logoUrl.brandName} : Contact Us` : "E-Commerce Website"}
        </title>
        <meta name="description" content="We offer a comprehensive selection of products from top brands at competitive prices. Contact us today to learn more about our products and services." />
        <link rel="canonical" href="https://jerseymep.com/contact-us" />
        <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />
      </Helmet></HelmetProvider>
      <Container>
        <section id="contact-us">
          <div className="row gx-3">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 mb-1">
              <Card className="my-1">
                <CardBody className="pt-2 pb-2">
                  <div className="contact-form">
                    <form>
                      <div className="d-flex flex-column align-items-center justify-content-center mb-1">
                        <h2 className="top-title">Please feel free to get in touch with us !</h2>
                      </div>
                      <Row>
                        {(!(Array.isArray(userProfile) && userProfile.some(obj => obj !== null && Object.keys(obj).length > 0)) && userProfile) && (
                          <><Col sm={6} md={6}>
                            <div class="form-group">
                              <label>
                                Name <span style={{ color: "#CA0015" }}>*</span>
                              </label>
                              <input
                                class="form-control"
                                name="userName"
                                value={formValues?.userName}
                                type="text"
                                placeholder="Enter full name"
                                onChange={(e) => HandleFormChange(e)}
                                onBlur={(e) => HandleBlur(e)}
                              />
                              {formError?.userName && (
                                <p className="form-error">{formError?.userName}</p>
                              )}
                            </div>
                          </Col>
                            <Col sm={6} md={6}>
                              <div class="form-group">
                                <label>
                                  Email <span style={{ color: "#CA0015" }}>*</span>
                                </label>
                                <input
                                  class="form-control"
                                  name="email"
                                  value={formValues?.email}
                                  type="text"
                                  placeholder="Enter your email address"
                                  onChange={(e) => HandleFormChange(e)}
                                  onBlur={(e) => HandleBlur(e)}

                                />
                                {formError?.email && (
                                  <p className="form-error">{formError?.email}</p>
                                )}
                              </div>
                            </Col>
                            <Col sm={6} md={6}>
                              <div class="form-group">
                                <label>Phone number</label>
                                <PhoneInput
                                  defaultCountry="QA"
                                  international
                                  countryCallingCodeEditable={false}
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  value={formValues.phoneNumber}
                                  onChange={(value) =>
                                    HandleChangePhone(value, "phoneNumber")
                                  }
                                  onBlur={(e, value) => HandleBlurPhoneNo(e, value)}
                                  class="form-control"
                                  placeholder="Mobile Number"
                                  enableSearch
                                  disableSearchIcon

                                />
                                {formError?.phoneNumber && (
                                  <p className="form-error">{formError?.phoneNumber}</p>
                                )}
                              </div>
                            </Col>
                            <Col sm={6} md={6}>
                              <div class="form-group">
                                <label>Company name</label>
                                <input
                                  class="form-control"
                                  name="companyName"
                                  value={formValues?.companyName}
                                  type="text"
                                  placeholder="Enter company name"
                                  onChange={(e) => HandleFormChange(e)}
                                  onBlur={(e) => HandleBlur(e)}
                                />
                                {formError?.companyName && (
                                  <p className="form-error">
                                    {formError?.companyName}
                                  </p>
                                )}
                              </div>
                            </Col></>)}
                        <Col sm={12} md={12}>
                          <div class="form-group">
                            <label>Subject<span style={{ color: "#CA0015" }}>*</span></label>
                            <input
                              class="form-control"
                              name="subject"
                              value={formValues?.subject}
                              type="text"
                              placeholder="Enter subject"
                              onChange={(e) => HandleFormChange(e)}
                              onBlur={(e) => HandleBlur(e)}
                            />
                            {formError?.subject && (
                              <p className="form-error">{formError?.subject}</p>
                            )}
                          </div>
                        </Col>
                        <Col sm={12} md={12}>
                          <div class="form-group">
                            <label>Your Message<span style={{ color: "#CA0015" }}>*</span></label>
                            <textarea
                              class="form-control"
                              name="message"
                              value={formValues?.message}
                              rows={4}
                              type="text"
                              placeholder="Enter message"
                              onChange={(e) => HandleFormChange(e)}
                              onBlur={(e) => HandleBlur(e)}
                            >
                              {" "}
                            </textarea>
                            {formError?.message && (
                              <p className="form-error">{formError?.message}</p>
                            )}
                          </div>
                        </Col>
                        <Col sm={12} md={12}>
                          <div class="form-group">
                            <label>Attachments</label>
                            <div className="file-section mt-2">
                              <div className="image-input">
                                <input
                                  className="form-control"
                                  id="imageInput"
                                  type="file"
                                  name="imageInput"
                                  accept=".jpg, .jpeg, .pdf"
                                  value=""
                                  onChange={handleFileSelect}
                                />
                                <label
                                  for="imageInput"
                                  className="image-button contract"
                                  id="document-images"
                                >
                                  <span>
                                    <i className="fa fa-plus"></i>
                                  </span>
                                  {loading && <p className="form-error">...Loading</p>}
                                  {errorFile && <p className="form-error">{errorFile}</p>}
                                  {/* {file.fileobj ? file.fileobj.name : "Add as jpg or png"} */}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {formValues?.attachments?.map((attachItem, index) => {
                          return (
                            <Col sm={12} md={12} className='mt-4'>
                              <div className='image-container'>
                                {attachItem.split('.').pop().trim() === "pdf" ?
                                  <object type="application/pdf" data={attachItem} width="100%" height="200" /> :
                                  <img src={attachItem} width="100%" height="100" />}
                                <button className="close-button" onClick={(event) => RemoveFile(event, attachItem)}>✖</button>
                              </div>
                            </Col>
                          )
                        })}
                        {(!(Array.isArray(userProfile) && userProfile.some(obj => obj !== null && Object.keys(obj).length > 0)) && userProfile) && (
                          <> <Col sm={12} md={12}>
                            <div style={{ transform: "scale(0.4)", transformOrigin: "0 0" }}>
                              <ReactCaptchaa
                                key={captchaKey}
                                ref={captchaRef}
                                captchaText={(captchaText) => {
                                  setCaptchaVerified(captchaText);
                                }}
                                captchaLength={6}
                                captchaButtonClick={(e) => { console.log(e) }}
                                onCanvasClick={(e) => { console.log(e) }}
                                height={75}
                                width={280}
                                iconName={'FiRefreshCw'}
                                iconColor={'#fff'}
                                fontSize={'4em'}
                                iconSize={'1em'}
                                containerClassName={'react-captcha'}
                                iconWrapperClassName={'react-captcha-icon-wrapper'}
                                canvasClassName={'react-captcha-canvas'}
                                iconClassName={'react-captcha-icon'}
                                charactersInclude={"0123456789abcdefghijklmnopqrstuvwxzABCDEFGHIJKLMNOP{logoUrl?.currency}STUVWXYZ!#$%^&"}
                              />
                            </div>
                          </Col>
                            <Col sm={12} md={12} className="mb-3">
                              <div style={{ marginTop: "-50px" }}>
                                <label className="mb-1">
                                  Captcha <span style={{ color: "#CA0015" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Captcha"
                                  name="captcha"
                                  value={formValues.captcha}
                                  onBlur={(e) => HandleBlur(e)}
                                  onChange={(e) => HandleFormChange(e)}
                                />
                                {formError.captcha && (
                                  <div className="form-error">{formError.captcha}</div>
                                )}
                              </div>
                            </Col></>)}
                        <Col sm={12} md={12}>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn shop-btn"
                              onClick={(e) => HandleSendMessage(e, "send-message")}
                            >
                              Send Message
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-1 contact-details-col mt-1">
              <Card>
                <CardHeader>
                  <h2>Contact Details</h2>
                </CardHeader>
                <CardBody className="pt-1">
                  <Row>
                    <Col md={12}>
                      <div className="d-flex align-items-center gap-2 my-2">
                        <img src={companyIcon} />
                        <p>{logoUrl?.companyName}</p>
                      </div>
                      <div className="d-flex align-items-center gap-2 my-2">
                        <img src={locationIcon} />
                        <p>{logoUrl?.companyName}, {logoUrl?.address?.addressLine1}, {logoUrl?.address?.addressLine2}, {logoUrl?.address?.city}, {logoUrl?.address?.state}, {logoUrl?.address?.country} - {logoUrl?.address?.postalCode}.</p>
                      </div>
                      <div className="d-flex align-items-baseline gap-2 my-2">
                        <img src={phoneIcon} />
                        <p>{logoUrl?.phoneNumber}</p>
                      </div>
                      <div className="d-flex align-items-center gap-2 my-2">
                        <img src={mailIcon} />
                        <p>{logoUrl?.email}</p>
                      </div>
                      <div className="d-flex align-items-center gap-2 my-2">
                        <img src={webIcon} />
                        <a href={logoUrl?.webSite} target="_blank">
                          {logoUrl?.webSite}
                        </a>
                      </div>
                    </Col>
                    <Col md={12} className="contact-image mt-5">
                      <div></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default ContactForm;
