import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProductRating from "../components/productRating";
import ProductToolTip from "../components/productToolTip";
import { FormatValueDecimal, FormatSeperateValue } from "../utils/validate";
import Spinner from "../common/spinner";
import { CTooltip } from '@coreui/react';
import useLogoImage from "../hooks/useLogoImage";
import AllProductsSkeleton from "./allProductsSkeleton";
import NoResultsFound from "../views/not-found/noResultsFound"

const ProductGrid = ({ productsList, productListloading }) => {
    const navigate = useNavigate();
    const [logoUrl, setLogoUrl] = useLogoImage();

    const renderStars = (overallRating) => {
        const fullStars = Math.floor(overallRating || 0);
        const halfStar = overallRating - fullStars >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;

        return (
            <>
                {Array(fullStars).fill().map((_, i) => <i key={`full-${i}`} className='fa fa-star'></i>)}
                {halfStar ? <i key="half" className='fa fa-star-half-o'></i> : null}
                {Array(emptyStars).fill().map((_, i) => <i key={`empty-${i}`} className='fa fa-star-o'></i>)}
            </>
        );
    };

    return (<>
        {productListloading ? <>
            <Row className="gx-1">
                <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton />
                <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton />
                <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton />
                <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton /> <AllProductsSkeleton />
            </Row>
        </> :
            <>
                <Row className="gx-1">
                    {productsList?.length > 0 ?
                        productsList?.map((products, index) => {
                            const discountPercentage = ((products?.displayRegularPrice - products?.displaySalePrice) / products?.displayRegularPrice) * 100;

                            return (
                                <Col className="product-listing-col" key={index}>
                                    <a href={`/product-detail/${products.productID || products.id}/`} target="_blank" rel="noopener noreferrer">
                                        <Card className='mb-1'>
                                            <CardBody>
                                                <div className='image-box'>
                                                    <img width="100%" src={products.featuredImage?.url || products.featuredImage} alt="product" loading="lazy" />
                                                </div>
                                                <div className='product-details mt-2'>
                                                    <h2><ProductToolTip productName={products.productName} /></h2>
                                                    <p>
                                                        <span style={{ fontWeight: "600" }}>Brand: </span>
                                                        <CTooltip content={products?.brandName} placement="bottom">
                                                            <span>{products?.brandName}</span>
                                                        </CTooltip>
                                                    </p>
                                                    <h3 className='rating'>
                                                        {renderStars(products?.overallRating)} ({products?.noOfRating || 0})
                                                    </h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h4>{logoUrl?.currency} {FormatValueDecimal(products?.displaySalePrice || 0)}</h4>
                                                        {discountPercentage > 0 && (
                                                            <p className="price-off" style={{color: "#1baa57", fontSize: "12px", fontWeight: "600"}}>
                                                            {FormatSeperateValue(Math.round(discountPercentage * 100) / 100) + "%"} Off
                                                        </p>                                                    
                                                        )}
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </a>
                                </Col>
                            );
                        }) : <NoResultsFound />
                    }
                </Row>
            </>}</>
    )
}

export default ProductGrid