import React, { Fragement, useState, useEffect, useContext, useRef, memo, Fragment } from 'react';
import { Link, useLocation } from "react-router-dom";
import LocationIcon from '../assets/images/icons/location.png';
import UserIcon from '../assets/images/icons/user.png';
import Flag from '../assets/images/icons/flag.png';
import { Container, Row, Col, InputGroup, Form, Tab, Nav } from 'react-bootstrap';
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import customLogger from "../common/loggerService";
import HeaderDropdown from "../pages/headerDropdown";
import { useNavigate } from 'react-router-dom';
import useProfile from '../hooks/useProfile';
import { Auth } from 'aws-amplify'
import { commonImages, Status, UserPoolDetails } from '../constants';
import { GetData } from '../providers/queryData';
import { resourceconstant } from '../constants';
import Autocomplete from 'react-autocomplete';
import useCart from '../hooks/useCartPage';
import useUserDetails from "../hooks/useUserDetails";
import ChangeLocation from './ChangeLocation';
import useChangeLocation from '../hooks/useChangeLocation';
import AllNotification from './notification/allNotification';
import { SearchValueContext } from '../context/SearchValueContext';
import NavbarDropdown from '../pages/navbarDropdown';
import useLogoImage from '../hooks/useLogoImage';
import { FormatValueDecimal } from '../utils/validate';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Header = ({ category, subCategory, HandleAllProducts, productsList }) => {
    const locations = useLocation()
    const [changeLocation, setChangeLocation] = useChangeLocation();
    const [cartPageItem, setCartPageItem] = useCart()
    const { searchValueTerm, setSearchValueTerm, searchTerm, setSearchTerm, search, setSearch } = useContext(SearchValueContext);
    const [suggestions, setSuggestions] = useState([]);
    const [userProfile] = useProfile()
    const [cartList, setCartList] = useState([])
    const [cartData, setCartData] = useState([])
    const [userDetails, setUserDetails] = useUserDetails();
    const [currentLocation, setCurrentLocation] = useState("")
    const [products, setProducts] = useState([]);
    const userid = userProfile?.attributes?.sub;
    const userName = userDetails?.userName
    const firstName = userDetails?.firstName
    const localName = localStorage.getItem('fullName')
    const navigate = useNavigate();
    const [navbarDropdown, setNavbarDropdown] = useState(false);
    const [logoUrl, setLogoUrl] = useLogoImage();

    useEffect(() => {
        const location = changeLocation?.filter((item) => item?.default === true)
        if (location?.length > 0) setCurrentLocation(location?.[0]?.address?.city)
        else setCurrentLocation(changeLocation?.[0]?.address?.city)
    }, [changeLocation])

    const CartList = () => {
        let filter = {
        }
        GetData(resourceconstant.LIST_CARTS, filter, setCartData)
    };

    useEffect(() => {
        if (cartData?.severity === "success") {
            setCartList(cartData?.data?.data?.listCarts?.items)
        }
    }, [cartData])

    useEffect(() => {
        CartList()
    }, [])

    const cartArray = localStorage.getItem('cartData');
    const cartCount = cartArray ? JSON.parse(cartArray) : [];
    const array = cartCount?.length === 0
    useEffect(() => {
        CartList()
    }, [array])
    const mergedCartList = [...cartCount, ...cartList.filter(item => !cartCount.some(cart => cart?.productID === item?.productID))];
    const userCartList = mergedCartList.filter(cart => cart?.userID === userid || cart?.userId === userid);
    const quantity = cartPageItem.find(item => item.selectedQuantity)
    const cartTotal = cartPageItem?.reduce((total, item) => total + (item.salesPrice * item?.selectedQuantity), 0);

    useEffect(() => {
        setProducts(productsList)
    }, [productsList])

    const handleSearchChange = (event) => {
        const searchValue = event.target.value;
        localStorage.setItem('searchTerm', searchValue);
        setSearch(searchValue)
        if (searchValue.length > 2) {
            const filteredSuggestions = products.filter(product =>
                product.productName.replace(/\s+/g, ' ').trim().toLowerCase().includes(searchValue.toLowerCase()) ||
                product.brandName.toLowerCase().includes(searchValue.toLowerCase()) ||
                product?.searchKey.toLowerCase().includes(searchValue.toLowerCase())
            );

            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSearchIconClick = () => {
        if (search.trim() !== '') {
            const searchTerms = search;
            const filteredProducts = products.filter(product =>
                product.productName.replace(/\s+/g, ' ').trim().toLowerCase().includes(searchTerms.toLowerCase()) ||
                product.brandName.toLowerCase().includes(searchTerms.toLowerCase()) ||
                product?.searchKey.toLowerCase().includes(searchTerms.toLowerCase())
            );
            localStorage.setItem('searchTerm', searchTerms);
            setSearchValueTerm(filteredProducts?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            setSearchTerm(searchTerms);
            setSuggestions([]);
            navigate('/search-product', {
                state: {
                    filteredProducts: filteredProducts,
                    searchKeyword: searchTerms,
                },
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (search.trim() !== '') {
                handleSearchIconClick();
            }
        }
    };

    const handleSuggestionClick = (e, item) => {
        setSuggestions([]);
        setSearchTerm('');
        setSearch('')
        localStorage.removeItem('searchTerm');
        navigate(`/product-detail/${item.id}/`, {
            state: {
                values: {
                    subproduct_id: item.id,
                    mainCategoryname: item.mainCategory,
                    subcategoryName: item.subCategory,
                    mainCategoryId: item.mainCategoryID,
                    subCategoryId: item.subCategoryID,
                    search: item.id,
                },
            },
        });
    };


    const HandleSignOut = async () => {
        localStorage.removeItem('username');
        localStorage.removeItem('fullName');
        localStorage.removeItem('IdToken');
        localStorage.clear()
        try {
            await Auth.signOut().then((res) => {
                window.location.reload()
            });
        } catch (err) {
            customLogger.error(err)
        }
    };

    useEffect(() => {
        if (locations.pathname !== '/checkout') {
            localStorage.setItem('checkoutDirect', false);
        }
        if (locations.pathname !== '/order-confirm') {
            localStorage.setItem('orderConfirm', false)
        }
    }, [])

    useEffect(() => {
        if (locations.pathname !== '/email-otp') {
            localStorage.removeItem('timeLeft')
        }
    }, [locations.pathname !== '/email-otp'])

    useEffect(() => {
        if (locations.pathname !== '/new-email-otp') {
            localStorage.removeItem('timeLeftNew')
        }
    }, [locations.pathname !== '/new-email-otp'])

    useEffect(() => {
        if (locations.pathname !== '/account-deletion-otp') {
            localStorage.removeItem('timeLeftAccount')
        }
    }, [locations.pathname !== '/account-deletion-otp'])

    const maxVisibleCategories = 6;
    const [isOpen, setIsOpen] = useState(false);

    const visibleCategories = category.slice(0, maxVisibleCategories);
    const hiddenCategories = category.slice(maxVisibleCategories);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setNavbarDropdown(true)
    }

    const handleNavbarDropdown = () => {
        setNavbarDropdown(true)
    }
    const [userInfo, setUserInfo] = useState([]);

    const GetUserProfileDetails = async () => {
        try {
            let filter = {};
            await GetData(resourceconstant.LIST_USER_DETAILS, filter, setUserInfo);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await GetUserProfileDetails();
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (userDetails && userDetails?.status === Status?.INACTIVE) {
            navigate('/login')
        }
    }, [userDetails])
    const wrapperStyle = {
        width: userid ? '100%' : '78%',
    };

    return (
        <>
            <HelmetProvider><Helmet>
                <title>{logoUrl?.brandName ? logoUrl.brandName : "E-Commerce Website"}</title>
                <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />
            </Helmet></HelmetProvider>
            <div className="header-bar">
                <div className="header-top-wrapper d-none">
                    <Container fluid>
                        <div className="top-bar">
                            <div className="language-drops">
                                <div className="language">
                                    <img src={Flag} />
                                    <select className="form-control">
                                        <option>English</option>
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className="location-drops">
                                <div className="location">
                                    <select className="form-control">
                                        <option>{logoUrl?.currency}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="header-outer-wrapper">
                    <Container>
                        <Row className='pt-2'>
                            <Col xxl={2} xl={2} lg={2} md={6} sm={6} xs={4} id="menuLogoColumn">
                                <div className='d-flex align-items-center gap-2 mt-1 mt-lg-0 mb-1 mb-lg-0'>
                                    <div id="menuToggle">
                                        <a onClick={toggleMenu} >
                                            <i className="fa fa-bars"></i>
                                        </a>
                                    </div>
                                    {isOpen && (
                                        <NavbarDropdown
                                            userid={userid}
                                            userName={firstName}
                                            localName={localName}
                                            category={category}
                                            subCategory={subCategory}
                                            setNavbarDropdown={setNavbarDropdown}
                                            navbarDropdown={navbarDropdown}
                                        />
                                    )}
                                    <div className="logo-wrapper text-center">
                                        <Link className="anchor logo-anchor" to="/">
                                            <picture className="logo">
                                                <img
                                                    src={logoUrl?.primaryLogo?.url}
                                                    alt="Logo" className='d-none d-sm-block'
                                                    loading='lazy'
                                                />
                                                <img
                                                    src={logoUrl?.secondaryLogo?.url}
                                                    alt="Logo" className='d-block d-sm-none'
                                                    loading='lazy'
                                                />
                                            </picture>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            {userid && (<Col xxl={2} xl={2} lg={2} md={6} sm={6} xs={12} className='delivery-location my-auto' id='deliveryLocationColumn'>
                                <div className="location-icon dropdown mt-1 mt-lg-0 mb-1 mb-lg-0 justify-content-between justify-content-sm-end justify-content-md-end justify-content-lg-start">
                                    <a className="dropbtn w-100 w-sm-auto">
                                        <div className='d-flex align-items-center gap-2'>
                                            <i class="fa fa-map-marker"></i>
                                            <div className='d-flex d-sm-block justify-content-between w-100'>
                                                <a className='location-a' title={currentLocation} href="#">{changeLocation?.length > 0 ? "Delivering to " + currentLocation : ""}
                                                    {changeLocation?.length > 0 ? <br /> : ""}
                                                </a>
                                                <button type='button' className='btn btn-link'>Change Location</button>
                                            </div>
                                        </div>
                                        <ChangeLocation />
                                    </a>
                                </div>
                            </Col>)}
                            <Col xxl={userid ? 4 : 7} xl={userid ? 4 : 7} lg={userid ? 4 : 6} md={userid ? 6 : 12} sm={userid ? 6 : 12} xs={12} className='search-box my-auto' id='searchColumn'>
                                <InputGroup className='justify-content-start justify-content-lg-center mt-1 mt-lg-0 mb-1 mb-lg-0'>
                                    <Autocomplete
                                        items={suggestions}
                                        getItemValue={item => item.productName}
                                        renderMenu={(items, value) => (
                                            <div className={`search-dropdown ${items.length > 4 ? 'h-fixed' : 'h-auto'}`}>{items}</div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
                                            <div key={item.id} className='product-drop' style={{ padding: '10px', backgroundColor: isHighlighted ? '#eee' : 'transparent' }} onClick={(e) => handleSuggestionClick(e, item)}>
                                                <Link to={`/product-detail/${item?.id}/`}>
                                                    <div className='d-flex gap-2'>
                                                        <div>
                                                            <img width="10%" src={item.featuredImage?.url} alt="product" loading='lazy' />
                                                        </div>
                                                        <div className='ms-2'>
                                                            <p className="pb-0 mb-0 fc-FF9226 fw-600">{item.productName}</p>
                                                            <div className="product-sub">
                                                                <p className="pb-0 mb-0 brandname">Brand:<span>{item.brandName}</span> </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                        value={search}
                                        onChange={handleSearchChange}
                                        onSelect={(value, item) => handleSuggestionClick(null, item)}
                                        inputProps={{
                                            placeholder: 'Search by Product Name, Brand',
                                            onKeyDown: handleKeyDown,
                                            className: 'autocomplete-input'
                                        }}
                                        wrapperStyle={wrapperStyle}
                                        closeIcon={<i className="fa fa-times" />}
                                    />
                                    <InputGroupText>
                                        <i className="fa fa-search" onClick={handleSearchIconClick} />
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col xxl={userid ? 4 : 3} xl={userid ? 4 : 3} lg={userid ? 4 : 4} md={6} sm={6} xs={8} className='my-auto' id='notifyAccountColumn'>
                                <div className='d-flex gap-3 align-items-center justify-content-end mt-1 mt-lg-0 mb-1 mb-lg-0'>
                                    <div className='notification'>
                                        <AllNotification />
                                    </div>
                                    <div className='cart-section'>
                                        <div className="cart">
                                            <Link to="/cart">
                                                <i class="fa fa-cart-plus"></i>
                                                <span className="badge">{cartPageItem?.length}</span>
                                                <span className='d-none d-sm-block'>{logoUrl?.currency} {cartTotal ? FormatValueDecimal(cartTotal) : 0}</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='account-section'>
                                        <div className="location account dropdown">
                                            <a style={{ cursor: "pointer" }} className="dropbtn d-flex align-items-center">
                                                {userDetails?.profileImageUrl ? (<img src={userDetails.profileImageUrl} alt="User Profile" />) : (<i className="fa fa-user-circle"></i>)}
                                                <div>
                                                    <Link to="/profile" className='d-none d-md-block account-a'>Account</Link>
                                                    <Link to="/profile">
                                                        <span title={userid ? `${userName || localName ? userName || localName : "Sign in"}` : "Sign in"}>{userid ? `${userName || localName ? userName || localName : "Sign in"}` : "Sign in"}</span>
                                                    </Link>
                                                </div>
                                                <div className="dropdown-content">
                                                    <h2>Welcome</h2>
                                                    <p className='mt-1 mb-2'>To access Account / Manage Orders</p>
                                                    <ul>
                                                        <li>
                                                            <Link to="/profile">Profile</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/address">Address</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/orders">Orders</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/wishlist">Wishlist</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact-us">Contact Us</Link>
                                                        </li>
                                                    </ul>
                                                    {userid ? (
                                                        <Link to="/" onClick={HandleSignOut}>
                                                            <button className='btn shop-btn'>Logout</button>
                                                        </Link>
                                                    ) : (
                                                        <Link to="/login">
                                                            <button className='btn shop-btn'>Login/Sign up</button>
                                                        </Link>)}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="header-bottom-wrapper">
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className='d-flex'>
                                    <ul className="d-flex mb-0 ps-0">
                                        <li className="dropbtn hamberger-icon" onClick={toggleMenu}>
                                            <a>
                                                <i className="fa fa-bars ms-0"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="dropdown w-100">
                                        <li className="dropbtn">
                                            <a href="/all-products" onClick={HandleAllProducts} className="me-2">All</a>
                                        </li>
                                        {visibleCategories.map((each, index) => (
                                            <HeaderDropdown products={products} key={index} MainCategory={each} category={category} />
                                        ))}
                                    </ul>
                                    {isOpen && (
                                        <NavbarDropdown products={products} userid={userid} userName={firstName} localName={localName} category={category} subCategory={subCategory} setNavbarDropdown={setNavbarDropdown} navbarDropdown={navbarDropdown} />
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Header;