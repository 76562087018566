import React, { useEffect, useState } from "react";
import { GetData } from "../../../providers/queryData";
import { resourceconstant, Website } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import ProductListQuery from "../../../common/ProductListQuery"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from "../../../hooks/useLogoImage";
import _ from "lodash";
const MainCategoryList = () => {
    const [mainCategoryId, setMainCategoryId] = useState("")
    const [filteredCategory, setFilteredCategory] = useState([])
    const [mainCategoryImage, setMainCategoryImage] = useState({ description: "" });
    const current = new URL(window.location.href);
    const pathSegments = current.pathname.split('/');
    const mainCategory = pathSegments?.length === 3 ? pathSegments[pathSegments.length - 1] : pathSegments[pathSegments.length - 2];
    const [logoUrl, setLogoUrl] = useLogoImage();

    useEffect(() => {
        GetCategoryListForFilter();
    }, [mainCategory])

    const GetCategoryListForFilter = () => {
        let filter = {
            pk: {
                eq: 'MAIN#',
            },
            slug: {
                eq: mainCategory
            }
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setFilteredCategory)
    };

    useEffect(() => {
        if (filteredCategory?.severity === "success") {
            const newCategoryId = filteredCategory?.data?.data?.listCategories?.items[0]?.id;
            setMainCategoryImage(filteredCategory?.data?.data?.listCategories?.items[0])
            setMainCategoryId(newCategoryId);
        }
    }, [filteredCategory]);

    const stripHtmlTags = (html) => {
        if (!html) return "";
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    };

    return (
        <>
            <HelmetProvider><Helmet>
                <title>{logoUrl?.brandName ? `${logoUrl.brandName} | ${_.startCase(mainCategory)}` : Website?.WEBSITE}</title>
                <meta name="description" content={stripHtmlTags(mainCategoryImage?.description)?.slice(0, 160) || "Jersey MEP | Online MEP Supplier in Qatar" } />

                <meta property="og:title" content={`${_.startCase(mainCategory)} | ${logoUrl?.brandName || "Jersey MEP | Online MEP Supplier in Qatar"}`} />
                <meta property="og:description" content={stripHtmlTags(mainCategoryImage?.description)?.slice(0, 200) || "Jersey MEP | Online MEP Supplier in Qatar" } />
                <meta property="og:url" content={`https://jerseymep.com/${mainCategory}`} />

                <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />
            </Helmet></HelmetProvider>
            <ProductListQuery page="mainCategory" mainCategoryId={mainCategoryId} mainCategory={mainCategory} mainCategoryImage={mainCategoryImage} />
        </>
    );
};

export default MainCategoryList;