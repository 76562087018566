import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ProductToolTip from './productToolTip';
import WidgetSpinner from '../common/widgetSpinner';
import SkeletonMaskLatestProducts from '../common/skeletonMaskLatestProducts';
import { CTooltip } from '@coreui/react';
import ReactGA from 'react-ga4';


export default function TopSellerProduct({ handleClickAnalytics, topLoading, topDeals, products }) {
    const navigate = useNavigate()
    return (
        <Row>
            {!topLoading && products && topDeals?.length > 0 ?
                topDeals?.slice(0, 4)?.map((arrivals, index) => {
                    const matchingDetails = products && arrivals && products?.filter(details => details.id === arrivals.productID);
                    const HandleImageClick = (e, platform) => {
                        e.preventDefault();
                        navigate(`/product-detail/${matchingDetails[0]?.id}/`, {
                            state: {
                                values: {
                                    subproduct_id: matchingDetails[0]?.id,
                                    mainCategoryname: matchingDetails[0]?.mainCategory,
                                    subcategoryName: matchingDetails[0]?.subCategory,
                                    mainCategoryId: matchingDetails[0]?.mainCategoryID,
                                    subCategoryId: matchingDetails[0]?.subCategoryID,
                                    search: matchingDetails[0]?.id,
                                },
                            },
                        });
                        ReactGA.event({
                            category: 'Mep Products',
                            action: 'Click',
                            label: platform,
                        });
                        window.scroll(0, 0);
                    };
                    return (
                        <Col md={6} className='col-6 px-1 product-col' key={index}>
                            <div className='carousel__item'>
                                <div className='image-box'>
                                    <img width="100%" onClick={(e) => HandleImageClick(e, matchingDetails[0]?.productName)} src={arrivals?.featuredImage?.url} alt="" loading='lazy' />
                                </div>
                                <div className='carousel__caption'>
                                    <a href={`/product-detail/${matchingDetails[0]?.id}/`} onClick={(e) => handleClickAnalytics(e, matchingDetails[0]?.productName)}>
                                        <p className='title' onClick={HandleImageClick}><span> <ProductToolTip productName={arrivals?.productName} /></span></p>
                                    </a>
                                    <p>Brand : <CTooltip content={arrivals.brandName} placement="bottom">
                                        <span>{arrivals.brandName}</span>
                                    </CTooltip></p>
                                </div>
                            </div>
                        </Col>
                    )
                })
                : topLoading && <><SkeletonMaskLatestProducts /><SkeletonMaskLatestProducts /><SkeletonMaskLatestProducts /><SkeletonMaskLatestProducts /></>
            }
        </Row>
    )
}