import Carousel from 'react-bootstrap/Carousel';
const BannerCarousel = ({ carousalImages }) => {

    return (
        <Carousel
            indicators={false}
            prevIcon={carousalImages[0]?.displayArrows ? <span className="fa fa-chevron-left"></span> : null}
            nextIcon={carousalImages[0]?.displayArrows ? <span className="fa fa-chevron-right"></span> : null}>
            {carousalImages.map((carousalItem, index) => (
                carousalItem?.image?.map((item, subIndex) => (
                    <Carousel.Item key={`${index}-${subIndex}`} interval={carousalItem?.pauseTime * 3000}>
                        <img
                            src={item?.url}
                            alt={item?.alterText}
                            style={{ transition: `transform ${carousalItem?.transitionSpeed}s` }}
                            className='w-100'
                            loading='lazy'
                        />
                    </Carousel.Item>
                ))
            ))}
        </Carousel>
    );
}

export default BannerCarousel;