import React from 'react';
import LogoImage from '../assets/images/logo.png';

function Spinner(){
    return (
        <div class="no-freeze-spinner">
            <div id="no-freeze-spinner">
                <div>
                    <img src={LogoImage} className="img-fluid" loading="lazy" />
                    <div></div>
                </div>
            </div>
        </div>
    )
}
export default Spinner;
