import React from 'react';
import { Link } from 'react-router-dom';
import DeteilsNotFound from "../../assets/images/icons/DetailsNotFound.png";

const DetailsNotFound = () => {


    return (
        <div className='not-found'>
            <img src={DeteilsNotFound} loading='lazy' />
            <div className='d-flex flex-column align-items-center'>
                <p>The product is currently out of stock</p>
            </div>
        </div>


    )
};

export default DetailsNotFound;
