import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import { resourceconstant, Status } from '../../constants';
import { GetData } from "../../providers/queryData"
import { DeleteFromWishList, GetProductList, GetProductDetailList, HandleAddToCartToLocal } from "../../utils/generalMethod"
import { showSuccessToastr } from "../../common/toastrService"
import useProfile from "../../hooks/useProfile"
import NoResultsFound from '../not-found/noResultsFound';
import ProductRating from '../../components/productRating';
import { useNavigate } from "react-router-dom";
import Amplify, { Auth } from 'aws-amplify';
import useCart from '../../hooks/useCartPage';
import ProductToolTip from '../../components/productToolTip';
import Spinner from '../../common/spinner';
import { FormatValueDecimal } from '../../utils/validate';
import { CTooltip } from '@coreui/react';
import useLogoImage from '../../hooks/useLogoImage';
import useUserDetails from '../../hooks/useUserDetails'
import WishlistCarouselSkeleton from '../../common/wishlistCarouselskeleton';

export default function WishlistTab() {
    const [userProfile, setUserProfile] = useProfile()
    const [userDetails, setUserDetails] = useUserDetails();
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [responseData, setResponseData] = useState(null);
    const [wishListData, setWishListData] = useState([]);
    const [deleteResponse, setDeleteResponse] = useState(null);
    const [productResponse, setProductResponse] = useState(null);
    const [productDetailResponse, setProductDetailResponse] = useState(null);
    const [productDetailList, setProductDetailList] = useState([])
    const [productList, setProductList] = useState([])
    const [addToCart, setAddTocart] = useState(false)
    const [dataSuccess, setDataSuccess] = useState(null);
    const [localCartData, setLocalCartData] = useState([]);
    const [cartPageItem, setCartPageItem] = useCart()
    const [loading, setLoading] = useState(false);
    const [productListResponse, setProductListResponse] = useState(null);
    const [productDetailListResponse, setProductDetailListResponse] = useState(null);
    const [productDetail, setProductDetail] = useState([])
    const [products, setProducts] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        const GetUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                if (!currentUser) navigate('/login')
            }
            catch {
                navigate('/login')
            }
        }
        GetUser()
    }, [])

    useEffect(() => {
        GetWishList();
    }, [userProfile?.attributes?.sub])

    const ProductLists = () => {
        const product = {
            status: {
                eq: "published"
            }
        }
        GetProductList(product, setProductListResponse)
        const productDetail = {
        }
        GetProductDetailList(productDetail, setProductDetailListResponse)
        setLoading(true);
    }

    useEffect(() => {
        ProductLists()
    }, [])

    useEffect(() => {
        if (productListResponse?.severity === "success") {
            setLoading(false);
            setProducts(productListResponse?.data?.data?.listProducts?.items);
        }
    }, [productListResponse])

    useEffect(() => {
        if (productDetailListResponse?.severity === "success") {
            setLoading(false);
            setProductDetail(productDetailListResponse?.data?.data?.listProductDetails?.items);
        }
    }, [productDetailListResponse])

    const GetWishList = () => {
        const userid = userProfile?.attributes?.sub;
        let filter = {
            userId: {
                eq: `${userid}`,
            },
        }

        GetData(resourceconstant.LIST_WISH_LISTS, filter, setResponseData)
    };

    useEffect(() => {
        if (responseData?.severity === "success") {
            setWishListData(responseData?.data?.data?.listWishLists?.items)
        }
    }, [responseData])

    const DeleteWishList = (event, wishListData) => {
        event.preventDefault();
        const data = {
            pk: "WL#",
            id: wishListData?.id
        }
        DeleteFromWishList(data, setDeleteResponse)
    }

    useEffect(() => {
        if (deleteResponse?.severity === "success") {
            showSuccessToastr("Product removed from wishlist")
            setDeleteResponse(null)
            GetWishList();
        }
        if (deleteResponse?.severity === "error") {
            showSuccessToastr("Failed to remove the product")
            setDeleteResponse(null)
        }
    }, [deleteResponse])

    const WishAddToCart = (event, item) => {
        event.preventDefault();
        let productFilter = {
            id: {
                eq: `${item?.productId}`,
            },
            status: {
                eq: "published"
            }
        }
        GetProductList(productFilter, setProductResponse)

        let productDetailFilter = {
            productID: {
                eq: `${item?.productId}`,
            },
        }
        GetProductDetailList(productDetailFilter, setProductDetailResponse)
    }

    useEffect(() => {
        if (productResponse?.severity === "success") {
            setProductList(productResponse?.data?.data?.listProducts?.items);
        }
    }, [productResponse])

    useEffect(() => {
        if (productDetailResponse?.severity === "success") {
            const data = productDetailResponse?.data?.data?.listProductDetails?.items
            const filteredProductsDetails = data?.filter(product =>
                productList?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
                    (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
            );
            setProductDetailList(filteredProductsDetails);
        }
    }, [productDetailResponse])


    useEffect(() => {
        if (addToCart === false && productList?.length > 0 && productDetailList?.length > 0) {
            setAddTocart(true);
            const price = productList[0]?.attrValue
                ?.map((item) => {
                    const matchingDetail = productDetailList.find(
                        (data) => data.attrValueID === item.attrValueID
                    );
                    return matchingDetail;
                })
                .filter((detail) => detail !== undefined);
            const result = price?.length > 0 ? price[0] : productDetailList[0]
            const userId = userProfile?.attributes?.sub;
            const userEmail = userProfile?.attributes?.email;
            const mobileNo = userDetails?.phoneNumber;
            const quantity = 1
            HandleAddToCartToLocal(productList[0], result, userId, userEmail, mobileNo, quantity, setLocalCartData, setDataSuccess, null, setCartPageItem)
        }
    }, [productList, productDetailList])

    useEffect(() => {
        if (dataSuccess === "success") {
            setProductResponse(null)
            setProductDetailResponse(null)
            setProductList([])
            setProductDetailList([])
            setAddTocart(false);
            setDataSuccess(null)
        }
    }, [dataSuccess])

    return (
        <section id="account" className='my-1'>
            <Container>
                <Row className='mt-1'>
                    <Col md={12}>
                        <Tab.Container>
                            <Card className='mx-5 wishlist'>
                                <Nav className="flex-column">
                                    <CardHeader>
                                        <Nav.Item><Nav.Link><h2>Wishlist ({wishListData?.length})</h2></Nav.Link></Nav.Item>
                                    </CardHeader>
                                    <CardBody>
                                        {!loading && wishListData?.length > 0 ?
                                            <>
                                                <Row>
                                                    {wishListData?.map((item, index) => {
                                                        const outStock = products?.filter(data => data?.id === item?.productId)
                                                        const productData = productDetail?.filter(data => data?.productID === item?.productId)

                                                        const stockItem = productData?.filter(product =>
                                                            outStock?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
                                                              (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
                                                          );

                                                        
                                                          const isDisabled = !outStock?.[0]?.allowPreOrder && (
                                                            outStock?.[0]?.showWhenZeroStock && outStock?.[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS || outStock?.[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS ||
                                                            stockItem?.length === 0
                                                        ) ? Status?.STOCKSTATUSOUT : ''

                                                        const HandleImageClick = (e) => {
                                                            e.preventDefault();
                                                            navigate(`/product-detail/${item.productId}/`, {
                                                                state: {
                                                                    values: {
                                                                        subproduct_id: item.productId,
                                                                        mainCategoryname: item.mainCategory,
                                                                        subcategoryName: item.subCategory,
                                                                        mainCategoryId: item.mainCategoryId,
                                                                        subCategoryId: item.subCategoryId,
                                                                        search: item.productId,
                                                                    },
                                                                },
                                                            });
                                                            window.scroll(0, 0);
                                                        };

                                                        const price = outStock[0]?.attrValue
                                                        ?.map((item) => {
                                                          const matchingDetail = stockItem.find(
                                                            (data) => data.attrValueID === item.attrValueID
                                                          );
                                                          return matchingDetail;
                                                        })
                                                        .filter((detail) => detail !== undefined);
                                                  
                                                      const checked = price ? price : stockItem ? stockItem : productData
                                                      const priceCondition = price?.[0] ? price?.[0] : stockItem[0] ? stockItem[0] : productData[0]
                                                  
                                                      const lowestPriceDetail = checked.reduce((lowest, current) => {
                                                        const currentSalePrice = current?.salePrice || current?.regularPrice;
                                                        const lowestSalePrice = lowest?.salePrice || lowest?.regularPrice;
                                                  
                                                        return currentSalePrice < lowestSalePrice ? current : lowest;
                                                      }, priceCondition);
                                                
                                                      const wishlist = lowestPriceDetail ? lowestPriceDetail : stockItem[0] ? stockItem[0] : productData[0] ? productData[0] : item
                                                        
                                                        return (<>
                                                            <Col md={3} className="px-0 col-12 mb-3" key={index}>
                                                                <div className='carousel__item'>
                                                                    <div className='image-box' onClick={HandleImageClick} >
                                                                        <img width="100%" src={outStock[0]?.featuredImage?.url ? outStock[0]?.featuredImage?.url : item?.productImage} alt="product" disabled={isDisabled} style={isDisabled ? { opacity: 0.3 } : { cursor: "pointer" }} />
                                                                    </div>
                                                                    <p className="out-stock" style={{ color: '#CA0015', fontWeight: 600 }}>{isDisabled ? Status?.STOCKSTATUSOUT : ''}</p>
                                                                    <div className='carousel__caption'>
                                                                        <p className='title' onClick={HandleImageClick}><ProductToolTip productName={outStock[0]?.productName ? outStock[0]?.productName : item?.productName} /></p>
                                                                        <p className='model'>
                                                                            <span style={{ fontWeight: "600" }}>Brand: </span>
                                                                            <CTooltip content={outStock[0]?.brandName ? outStock[0]?.brandName : item?.brandName} placement="bottom">
                                                                                <span>{outStock[0]?.brandName ? outStock[0]?.brandName : item?.brandName}</span>
                                                                            </CTooltip>
                                                                        </p>
                                                                        <ProductRating productid={item?.productId} products={products} />
                                                                        <h4 className='price'>{logoUrl?.currency} {FormatValueDecimal(wishlist?.salePrice)}</h4>
                                                                        <div className='my-2' disabled={isDisabled} style={isDisabled ? { opacity: 0.5, cursor: 'not-allowed' } : { cursor: 'pointer' }}>
                                                                            <button type="button" className='btn cart-btn' onClick={(e) => WishAddToCart(e, item)} disabled={isDisabled} style={isDisabled ? { opacity: 0.5, cursor: 'not-allowed' } : { cursor: 'pointer' }}>Move to Cart</button>
                                                                        </div>
                                                                        <a className='delete-btn' href="#" onClick={(e) => DeleteWishList(e, item)}>Delete from Wishlist</a>
                                                                    </div>
                                                                </div>
                                                            </Col></>
                                                        )
                                                    })
                                                    }
                                                </Row>
                                            </>
                                            :
                                            loading ? <><Row><WishlistCarouselSkeleton /><WishlistCarouselSkeleton /><WishlistCarouselSkeleton /><WishlistCarouselSkeleton /> </Row></> : wishListData?.length === 0 && <NoResultsFound />
                                        }
                                    </CardBody>
                                </Nav>
                            </Card>
                        </Tab.Container>
                    </Col>
                </Row>

            </Container>
        </section>

    )
}