import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { MainListProducts } from "../../../providers/queryData";
import { Status } from "../../../constants";
import { FormatValueDecimal } from "../../../utils/validate";
import ProductRating from "../../../components/productRating";
import { resourceconstant } from "../../../constants";
import { GetData } from "../../../providers/queryData";
import ProductToolTip from "../../../components/productToolTip";
import { CTooltip } from '@coreui/react';
import useLogoImage from "../../../hooks/useLogoImage";

const SimilarProducts = ({
  productList,
  productDetailList,
  productDetails,
  setSimilarProducts,
  similarProducts
}) => {
  const [logoUrl, setLogoUrl] = useLogoImage();
  const [products, setProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [productsDataDetail, setProductsDataDetail] = useState([]);
  const [productDetailsList, setProductDetailsList] = useState([])
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 2,
    },
  };

  const ProductList = () => {
    let filter = {
      status: {
        eq: "published",
      },
    };
    GetData(resourceconstant.PRODUCT_LIST, filter, setProductsData);
  };

  useEffect(() => {
    if (productsData?.severity === "success") {
      setProducts(productsData?.data?.data?.listProducts?.items)
    }
  }, [productsData])

  const ProductListDetail = () => {
    let filter = {};
    GetData(resourceconstant.PRODUCT_DETAIL_LIST, filter, setProductsList);
  };

  useEffect(() => {
    if (productsList?.severity === "success" && products) {
      const data = productsList?.data?.data?.listProductDetails?.items
      const filteredProductsDetails = data?.filter(product =>
        products?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
            (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
    );

    const filteredProducts = products?.filter(product =>
      data?.some(detail => detail.productID === product.id && detail.stockStatus === Status?.INSTOCKSTATUS ||
          (detail.stockStatus === Status?.OUTOFSTOCKSTATUS && (product.allowPreOrder || product.showWhenZeroStock)))
  );

      setProductDetailsList(filteredProducts)
      setProductsDataDetail(filteredProductsDetails)
    }
  }, [productsList, products])

  useEffect(() => {
    ProductList()
    ProductListDetail()
  }, [])

  const CustomDot = ({ active, onClick }) => {
    return (
      <li>
        <button
          className={`custom-dot ${active ? "custom-dot--active" : ""}`}
          onClick={() => onClick()}
        />
      </li>
    );
  };
  const similarProductsItem = productDetailsList.filter((product) => {
    const currentProductID = productList[0]?.id;

    if (product.id === currentProductID) {
      return false;
    }

    const productName1 = product.productName.toLowerCase();
    const productName2 = productList[0]?.productName.toLowerCase();

    if (typeof productName1 !== 'string' || typeof productName2 !== 'string') {
      return false;
    }

    const words1 = productName1?.split(" ");
    const words2 = productName2?.split(" ");

    for (const word1 of words1) {
      for (const word2 of words2) {
        if (word1 === word2) {
          return true;
        }
      }
    }
    return false;
  });
  const SimilarProductsCategory = productDetailsList.filter((product) => {
    const currentProductID = productList[0]?.id;

    if (product.id === currentProductID) {
      return false;
    }

    const subcategoryMatch = product.subCategoryID === productList[0]?.subCategoryID;

    if (!subcategoryMatch) {
      return false;
    }

    const matchingDetails = productsDataDetail?.filter(details => details.productID === product.id);
    return matchingDetails?.length > 0;
  });

  useEffect(() => {
    if (SimilarProductsCategory || similarProductsItem) {
      const similar = SimilarProductsCategory?.length > 0 ? SimilarProductsCategory : similarProductsItem;
  
      const filteredSimilar = similar.filter((data) => {
        const matchingDetails = productsDataDetail?.filter((details) => details.productID === data.id);
        return matchingDetails?.length > 0;
      });
  
      setSimilarProducts((prevSimilarProducts) => {
        const isDifferent = JSON.stringify(prevSimilarProducts) !== JSON.stringify(filteredSimilar);
        return isDifferent ? filteredSimilar : prevSimilarProducts;
      });
    }
  }, [SimilarProductsCategory, similarProductsItem, productsDataDetail]);

  return (
    <>
      <Carousel
        responsive={responsive}
        arrows={true}
        swipeable={true}
      >
        {similarProducts.map((data, index) => {
          const matchingDetails = productsDataDetail?.filter(details => details.productID === data.id);
          const priceData = data?.attrValue?.map(value =>
            matchingDetails.find(item =>
              item.attrValueID === value.attrValueID
            )
          ).filter(Boolean);
          const HandleImageClick = (e) => {
            e.preventDefault();
            navigate(`/product-detail/${data.id}/`, {
              state: {
                values: {
                  subproduct_id: data.id,
                  mainCategoryname: data.mainCategory,
                  subcategoryName: data.subCategory,
                  mainCategoryId: data.mainCategoryID,
                  subCategoryId: data.subCategoryID,
                  search: data.id,
                },
              },
            });
            window.scroll(0, 0);
          };

          return (
            <div key={index} className="carousel__item mb-3">
              <div className="image-box">
                <img
                  width="100%"
                  src={data?.featuredImage?.url}
                  loading="lazy"
                  alt="product"
                  onClick={HandleImageClick}
                />
              </div>
              <div className="carousel__caption">
                <p className="title" onClick={HandleImageClick}>
                  <span><ProductToolTip productName={data.productName}>{data.productName}</ProductToolTip></span>
                </p>
                <p className='model'>
                  <span style={{ fontWeight: "600" }}>Brand: </span>
                  <CTooltip content={data?.brandName} placement="bottom">
                    <span>{data?.brandName}</span>
                  </CTooltip>
                </p>
                <ProductRating productid={data?.id} products={productDetailsList} />
                <h4 className="price">
                  {logoUrl?.currency}{" "}
                  {FormatValueDecimal(priceData?.[0]?.salePrice ? priceData?.[0]?.salePrice : matchingDetails[0]?.salePrice)}
                </h4>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};
export default SimilarProducts;
