import React, { useEffect, useState } from 'react';
import { Col, Container, Card, CardBody, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resourceconstant } from '../../constants';
import { UpdateOrderItem, GetDropDownMaster, GetCompanyDetails } from "../../utils/generalMethod";
import { DropDownComponent } from "../../utils/dropDownComponet";
import ValidateField from "../../utils/useValid";
import {
    FieldNameConstant,
    ValidateName,
} from "../../constants/validateMsgConstant";
import awsmobile from "../../aws-exports"
import { Amplify, Storage } from 'aws-amplify';
import { v4 as uuid } from "uuid";
import useProfile from "../../hooks/useProfile";
import { checkValue, PriceFormat } from "../../utils/validate"
import { showSuccessToastr } from "../../common/toastrService"
import { GetData, PostData } from "../../providers/queryData"
import useLogoImage from '../../hooks/useLogoImage';

Amplify.configure(awsmobile)

export default function ReturnProduct() {
    const location = useLocation();
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useProfile()
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [cancelOrderReason, setCancelOrderReason] = useState([])
    const [cancelOrderDrp, setCancelOrderDrp] = useState([])
    const [cancelReason, setCancelReason] = useState(null);
    const [orderProductItem, setOrderProductItem] = useState({})
    const [ordersDetails, setOrdersDetails] = useState({})
    const [formError, setFormError] = useState({});
    const [returnForm, setReturnForm] = useState({
        returnReason: "",
        comments: "",
        attachments: [],
        returnType: "",
        pickUpAddress: "",
        expectedPickUpDate: "",
        expectedPickUpTime: "",
    })
    const [file, setFile] = useState({ fileobj: "", fileurl: "" });
    const [productReturnResponse, setProductReturnResponse] = useState(null)
    const [companyResponse, setCompanyResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errorFile, setErrorFile] = useState("")
    const [listConfigData, setListConfigData] = useState(null);
    const [configData, setConfigData] = useState(null);

    useEffect(() => {
        if (location?.state) {
            setOrdersDetails(location?.state?.orderDetail)
            setOrderProductItem(location?.state?.orderItem)
        }
    }, [location?.state])

    useEffect(() => {
        let data = {
            pk: {
                eq: `RETURNREASON#`,
            },
        };
        GetDropDownMaster(data, setCancelOrderReason)

        let companyData = {
            pk: {
                eq: `COMP#`,
            },
        };
        GetCompanyDetails(companyData, setCompanyResponse)
    }, [])

    useEffect(() => {
        if (cancelOrderReason?.severity === "success") {
            setCancelOrderDrp(cancelOrderReason?.data?.data?.listDropDownMasters?.items)
        }
    }, [cancelOrderReason])

    useEffect(() => {
        setReturnForm({
            ...returnForm,
            returnReason: cancelReason?.value,
        });
    }, [cancelReason]);

    const HandleFormChange = (e) => {
        const { name, value, type, checked } = e.target
        setReturnForm({
            ...returnForm,
            [name]: value,
        });

        // re-run the validation check on the changed field value
        const errors = validation({
            ...returnForm,
            [name]: value,
        });
        // update the formErrors state
        setFormError({
            ...formError,
            [name]: errors[name],
        });
    }

    const validation = (values) => {
        const errors = {};
        errors.returnReason = ValidateField(values.returnReason, FieldNameConstant?.RETURN_REASON, [
            ValidateName?.REQUIRED
        ]);

        errors.comments = ValidateField(values.comments, FieldNameConstant?.COMMENTS, [
            ValidateName?.REQUIRED
        ]);

        let error = {};
        Object.keys(errors).forEach((key) => {
            if (errors[key] !== undefined) error[key] = errors[key];
        });
        return error;
    };

    const uploadToS3 = async (fileParam) => {
        const renamedFileName = `${Date.now()}_${fileParam.name}`;
        await Storage.put(renamedFileName, fileParam, { contentType: fileParam?.type }).then(resp => {
            const fileUploadedUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_user_files_s3_bucket_region}.amazonaws.com/public/${resp?.key}`;

            setReturnForm({ ...returnForm, attachments: [...returnForm.attachments, fileUploadedUrl] })
            setLoading(false);
        }).catch(error => {
            setErrorFile("File Upload Fail! Please try again")
            setLoading(false);
        })
    }

    const RemoveFile = async (event, fileParam) => {
        event?.preventDefault();
        await Storage.remove(fileParam).then(resp => {
            setReturnForm({ ...returnForm, attachments: returnForm.attachments?.filter(item => item !== fileParam) })
        }).catch(error => { console.error("error", error) })
    }

    const handleFileSelect = (e) => {
        setLoading(true)
        setErrorFile("");
        const url = URL.createObjectURL(e.target.files[0]);
        setFile({ fileobj: e.target.files[0], fileurl: url });
        uploadToS3(e.target.files[0])
    }

    const HandleCancel = () => {
        navigate('/orders', {
            state: {
                from: "return",
                orderItem: orderProductItem,
                orderDetail: ordersDetails
            }
        })
    }

    const ReturnProductSubmit = (refNo) => {
        let companyAddress = companyResponse?.data?.data?.listCompanyDetails?.items?.[0]?.address
        delete companyAddress?.__typename;
        let pickUpAddress = ordersDetails?.shippingAddress
        delete pickUpAddress?.__typename;
        let returnData = {
            returnReference: refNo,
            reason: returnForm?.returnReason,
            comment: returnForm?.comments,
            quantity: orderProductItem?.quantity,
            total: orderProductItem?.totalPrice,
            attachments: returnForm?.attachments,
            paymentMode: ordersDetails?.paymentDetails?.paymentMode,
            paymentMethod: ordersDetails?.paymentDetails?.paymentMethod,
            returnType: "Refund",
            returnAddress: companyAddress,
            pickUpDetails: {
                pickUpAddress: pickUpAddress,
            },
            status: "Requested",
            returnStatusUpdate: [{
                returnStatus: "Requested",
                updatedAt: new Date(),
                updatedBy: ordersDetails?.userName
            }],
            createdAt: new Date()
        }

        // event.preventDefault();
        let data = {
            pk: orderProductItem?.pk,
            id: orderProductItem?.id,
            returnRefundActivity: returnData,
            searchKey: orderProductItem?.searchKey + '#' + refNo.toLowerCase()
        }
        UpdateOrderItem(data, setProductReturnResponse)
    }

    const ReturnProduct = (event, item) => {
        event.preventDefault();
        if (Object.keys(validation(returnForm)).length === 0) {
            GetReferenceNo()
        }
        else {
            setFormError(validation(returnForm));
        }
    }

    useEffect(() => {
        if (productReturnResponse?.severity === "success") {
            showSuccessToastr("Return request sent successfully.")
            navigate('/orders', {
                state: {
                    from: "return",
                    orderItem: productReturnResponse?.data?.data?.updateOrderItem,
                    orderDetail: ordersDetails
                }
            })
        }
    }, [productReturnResponse])

    const GetReferenceNo = () => {
        GetData(resourceconstant?.LIST_CONFIGURATION, {}, setListConfigData);
    }

    useEffect(() => {
        if (listConfigData?.severity === "success") {
            const refContact = listConfigData?.data?.data?.listConfigurations?.items?.filter((item) => item?.configurationDescription === "creating Ref ID for return")
            if (refContact?.length > 0) {
                let data = {
                    pk: "REF#",
                    id: refContact[0]?.id,
                    continuousSettings: {
                        ...refContact[0]?.continuousSettings,
                        runningNumber: (refContact[0]?.continuousSettings?.runningNumber * 1) + 1
                    },
                    modifiedTime: new Date(),
                    modifiedBy: userProfile?.attributes?.email
                }
                delete data?.continuousSettings?.__typename;
                PostData(resourceconstant?.UPDATE_CONFIGURATION, data, setConfigData);
            }
            else {
                let data = {
                    pk: "REF#",
                    id: uuid(),
                    configurationDescription: "creating Ref ID for return",
                    continuousSettings: {
                        prefixValue: "RTREF",
                        numberOfDigits: 4,
                        includeYearSuffix: true,
                        startingNumber: 1,
                        runningNumber: 1
                    },
                    type: "continuous",
                    modifiedTime: new Date(),
                    modifiedBy: userProfile?.attributes?.email
                }
                PostData(resourceconstant?.CREATE_CONFIGURATION, data, setConfigData);
            }
        }
    }, [listConfigData])

    useEffect(() => {
        if (configData?.severity === "success") {
            let RefConfigData = configData?.data?.data?.createConfiguration
            if (configData?.data?.data?.updateConfiguration)
                RefConfigData = configData?.data?.data?.updateConfiguration
            let refNo = "RTREF" + (new Date()).getFullYear() + "0000" + RefConfigData?.continuousSettings?.runningNumber
            ReturnProductSubmit(refNo)
        }
    }, [configData])
    return (
        <>
            <section id="return-product" className='mt-3'>
                <Container>
                    <Card className='mb-1'>
                        <CardBody>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="bread-crumbs">
                                        <nav>
                                            <Link to="/" className="breadcrumb-not-active">
                                                Home
                                            </Link>
                                            <span className="breadcrumb-arrow">/</span>
                                            <Link className="breadcrumb-not-active" to="/orders">
                                                Orders
                                            </Link>
                                            <span className="breadcrumb-arrow">/</span>
                                            <Link className="breadcrumb-not-active" to="/orders" state={{ from: "return", orderItem: orderProductItem, orderDetail: ordersDetails }}>
                                                {orderProductItem?.pk?.replace("ORDERI#", "")}
                                            </Link>
                                            <span className="breadcrumb-arrow">/</span>
                                            <a className="breadcrumb-active">Return Product</a>
                                        </nav>
                                    </div>
                                </Col>
                            </Row>
                            <h2 className='sub-title mb-1'>Return the product</h2>
                            <p className='fw-normal mb-2'>Please note that the return and refund are subjected to {logoUrl?.brandName}’s Return policy</p>
                            <div className='product-table mb-2'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='product-list'>
                                                    <div className='pdt-img me-3'>
                                                        <img src={orderProductItem?.itemDetail?.image} className='img-fluid' style={{ width: "78px", height: "90px" }} />
                                                    </div>
                                                    <div className='pdt-detail'>
                                                        <h6>{orderProductItem?.itemDetail?.productName} {orderProductItem?.attrValue && "-"} {orderProductItem?.attrName} {orderProductItem?.attrValue}</h6>
                                                        <p className="pdt-model" style={{ color: "black", fontSize: "16px", fontWeight: "400", marginBottom: "4px" }}>
                                                            {checkValue(orderProductItem?.itemDetail?.brand) !== "" && <span>Brand:</span>} {orderProductItem?.itemDetail?.brand}
                                                            {(checkValue(orderProductItem?.itemDetail?.model) !== "" && checkValue(orderProductItem?.itemDetail?.brand) !== "") && <span> | </span>}
                                                            {checkValue(orderProductItem?.itemDetail?.model) !== "" && <span>Model:</span>}  {orderProductItem?.itemDetail?.model}
                                                            {(checkValue(orderProductItem?.itemDetail?.sku) !== "") && (checkValue(orderProductItem?.itemDetail?.brand) !== "" || checkValue(orderProductItem?.itemDetail?.model) !== "") && <span> | </span>}
                                                            {checkValue(orderProductItem?.itemDetail?.sku) !== "" && <span>SKU:</span>} {orderProductItem?.itemDetail?.sku}
                                                        </p>
                                                        <p className='qty-price'><span>Unit Price: </span>{logoUrl?.currency} {PriceFormat(orderProductItem?.itemDetail?.price)} <span>|</span> <span>Qty: </span> {orderProductItem?.quantity}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="form-group mb-1">
                                        <h4 className="sub-title">Why you are returning the product ?</h4>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <DropDownComponent
                                            placeHolder="select reason"
                                            pageName="cancelorder"
                                            dpDownName="reason"
                                            stateData={cancelOrderDrp}
                                            controlName="reason"
                                            handleChange={(e) => setCancelReason(e)}
                                            value={cancelReason}
                                        />
                                        {formError?.returnReason && (<p className="form-error">{formError?.returnReason}</p>)}
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group mb-1 mt-2">
                                        <h4 className="sub-title">Comment</h4>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <textarea className='form-control' rows={5} placeholder='Enter' name="comments" onChange={HandleFormChange}></textarea>
                                        {formError?.comments && (<p className="form-error">{formError?.comments}</p>)}
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group my-1 mt-2">
                                        <h4 className="sub-title">Add Photos</h4>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="file-section mt-2">
                                        <div className="image-input">
                                            <input className="form-control" id="imageInput" type="file" name="imageInput" accept=".jpg, .jpeg, .pdf" value="" onChange={handleFileSelect} />
                                            <label for="imageInput" className="image-button contract" id="document-images"><span><i className='fa fa-plus'></i></span></label>
                                            {loading && <p className="form-error">...Loading</p>}
                                            {errorFile && <p className="form-error">{errorFile}</p>}
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={12} md={12} className='mt-4'>
                                    {returnForm?.attachments?.map((attachItem, index) => {
                                        return (
                                            <div className='image-container'>
                                                {attachItem.split('.').pop().trim() === "pdf" ?
                                                    <object type="application/pdf" data={attachItem} width="100%" height="200" /> :
                                                    <img src={attachItem} width="100%" height="100" />}
                                                <button className="close-button" onClick={(event) => RemoveFile(event, attachItem)}>✖</button>
                                            </div>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className='mb-3'>
                        <CardBody>
                            {ordersDetails.deliveryInfo?.deliveryType === "Doorstep" && (<><h2 className='sub-title mb-1'>Pickup Instruction</h2>
                                <p className='fw-normal'>Your package will be collected by the courier service during the delivery of the replacement/return of the ordered item(s). Please have your return item ready for pickup at that time. A printer is not required, as the carrier will provide the label for you.</p>
                                <hr /></>)}
                            <Row>
                                <Col md={8}>
                                    {ordersDetails.deliveryInfo?.deliveryType === "Doorstep" && (<><h2 className='sub-title mb-3 fs-6'>Pickup Address</h2>
                                        <div className='user-address'>
                                            <h4>{ordersDetails?.shippingUserDetails?.userName}</h4>
                                            <br />                                         
                                                <p>
                                                {ordersDetails?.shippingAddress?.addressLine1 && `${ordersDetails.shippingAddress.addressLine1}, `}
                                                {ordersDetails?.shippingAddress?.addressLine2 && `${ordersDetails.shippingAddress.addressLine2}, `}
                                                {ordersDetails?.shippingAddress?.street && `${ordersDetails.shippingAddress.street}, `}<br />
                                                {ordersDetails?.shippingAddress?.city && `${ordersDetails.shippingAddress.city}, `}
                                                {ordersDetails?.shippingAddress?.state && `${ordersDetails.shippingAddress.state}, `}<br />
                                                {ordersDetails?.shippingAddress?.country && `${ordersDetails.shippingAddress.country}`}
                                                {ordersDetails?.shippingAddress?.postalCode && ` - ${ordersDetails.shippingAddress.postalCode}`}
                                                </p>
                                                <p>
                                                {ordersDetails?.shippingUserDetails?.phoneNumber && (
                                                    <span>Contact Number: {ordersDetails.shippingUserDetails.phoneNumber}</span>
                                                )}
                                                </p>
                                        </div></>)}
                                </Col>
                                <Col md={12}>
                                    <div className='d-flex bottom-buttons aign-items-end justify-content-end'>
                                        <button type='button' className='btn cancel-btn mb-2 me-2' onClick={HandleCancel}>Cancel</button>
                                        <button type='button' className='btn confirm-btn mb-2 me-2' onClick={(e) => ReturnProduct(e)}>Confirm Return</button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </section >
        </>
    )
}