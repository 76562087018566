import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import CommentIcon from '../../assets/images/icons/message.png';
import { GetData } from '../../providers/queryData';
import { PolicyContent, resourceconstant, Website } from '../../constants';
import NoContentFound from '../not-found/noContent';
import Spinner from '../../common/spinner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from '../../hooks/useLogoImage';
const ShippingPolicy = () => {
    const [shippingPolicyContent, setShppingPolicyContent] = useState([]);
    const [shippingPolicy, setShippingPolicy] = useState([])
    const [loading, setLoading] = useState(true)
    const [logoUrl, setLogoUrl] = useLogoImage();

    const GetPolicyContent = () => {
        let filter = {}
        GetData(resourceconstant.POLICY_CONTENTS, filter, setShippingPolicy)
    };

    useEffect(() => {
        if (shippingPolicy?.severity === "success") {
            const content = shippingPolicy?.data?.data?.listContents?.items
            const policyCon = content.filter(item => item.title && item.content && item?.contentName?.startsWith(PolicyContent?.SHIPPINGPOLICY));
            setShppingPolicyContent(policyCon)
            setLoading(false)
        }
    }, [shippingPolicy])

    useEffect(() => {
        GetPolicyContent()
    }, [])

    return (
        <>
            <HelmetProvider><Helmet>
                <title>
                    {logoUrl?.brandName ? `${logoUrl.brandName} : ${PolicyContent?.SHIPPINGPOLICY}` : Website?.WEBSITE}
                </title>
                <meta name="description" content="Get details on JerseyMEP's shipping process, including timelines, costs, and any restrictions. Learn how to track your order." />
                <link rel="canonical" href="https://jerseymep.com/shipping-policy" />
                <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />

                <meta property="og:title" content="Jersey MEP - Shipping Policy" />
                <meta property="og:description" content="Find out everything about JerseyMEP's shipping process, including delivery times, costs, and order tracking." />
                <meta property="og:url" content="https://jerseymep.com/shipping-policy" />
                <meta property="og:type" content="website" />
            </Helmet></HelmetProvider>
            <section id="policy-sec" className='my-3'>
                <Container>
                    <Row className='mt-1'>
                        <Col md={12}>
                            {!loading && shippingPolicyContent?.length > 0 ? (
                                shippingPolicyContent.map((item, index) => (
                                    <Card>
                                        <CardHeader>
                                            <h2><img src={CommentIcon} />{item?.title}</h2>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="policy-right">
                                                        <div className='policy-description' dangerouslySetInnerHTML={{ __html: item?.content }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))
                            ) : loading ? <Spinner /> : shippingPolicyContent?.length === 0 && <NoContentFound />}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ShippingPolicy
