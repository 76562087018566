import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import CommentIcon from '../../assets/images/icons/message.png';
import { GetData } from '../../providers/queryData';
import { PolicyContent, resourceconstant, Website } from '../../constants';
import NoContentFound from '../not-found/noContent';
import Spinner from '../../common/spinner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from '../../hooks/useLogoImage';
const CookiePolicy = () => {
    const [privacyPolicyContent, setPrivacyPolicyContent] = useState([]);
    const [privacyPolicy, setPrivacyPolicy] = useState([])
    const [loading, setLoading] = useState(true)
    const [logoUrl, setLogoUrl] = useLogoImage();

    const GetPolicyContent = () => {
        let filter = {}
        GetData(resourceconstant.POLICY_CONTENTS, filter, setPrivacyPolicy)
    };

    useEffect(() => {
        if (privacyPolicy?.severity === "success") {
            const content = privacyPolicy?.data?.data?.listContents?.items
            const policyCon = content.filter(item => item.title && item.content && item?.contentName?.startsWith(PolicyContent?.COOKIEPOLICY));
            setPrivacyPolicyContent(policyCon)
            setLoading(false)
        }
    }, [privacyPolicy])

    useEffect(() => {
        GetPolicyContent()
    }, [])

    return (
        <>
            <HelmetProvider><Helmet>
                <title>
                    {logoUrl?.brandName ? `${logoUrl.brandName} : ${PolicyContent?.COOKIEPOLICY}` : Website?.WEBSITE}
                </title>
                <meta name="description" content="Understand how Jersey MEP uses cookies on their website. Learn about your privacy and how to manage cookie preferences." />
                <link rel="canonical" href="https://jerseymep.com/cookie-policy" />
                <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />

                <meta property="og:title" content="Jersey MEP - Cookie Policy" />
                <meta property="og:description" content="Learn how Jersey MEP uses cookies and how you can manage your preferences to enhance your privacy." />
                <meta property="og:url" content="https://jerseymep.com/cookie-policy" />
                <meta property="og:type" content="website" />
            </Helmet></HelmetProvider>
            <section id="policy-sec" className='my-3'>
                <Container>
                    <Row className='mt-1'>
                        <Col md={12}>
                            {!loading && privacyPolicyContent?.length > 0 ? (
                                privacyPolicyContent.map((item, index) => (
                                    <Card>
                                        <CardHeader>
                                            <h2><img src={CommentIcon} />{item?.title}</h2>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="policy-right">
                                                        <div className='policy-description' dangerouslySetInnerHTML={{ __html: item?.content }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))
                            ) : loading ? <Spinner /> : privacyPolicyContent?.length === 0 && <NoContentFound />}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CookiePolicy
