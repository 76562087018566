import React, { useState, useEffect } from "react";
import { GetData } from "../../../providers/queryData";
import { resourceconstant, Website } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import ProductListQuery from "../../../common/ProductListQuery"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLogoImage from "../../../hooks/useLogoImage";
import _ from "lodash";
const SubCategoryList = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const [categoryList, setcategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [category, setCategory] = useState([])
    const [mainCategoryId, setMainCategoryId] = useState("")
    const [subCategoryId, setSubCategoryId] = useState("")
    const [subCategoryDetail, setSubCategoryDetail] = useState({});
    const current = new URL(window.location.href);
    const pathSegments = current.pathname.split('/');
    const subCategory = pathSegments?.length === 4 ? pathSegments[pathSegments.length - 1] : pathSegments[pathSegments.length - 2];
    const mainCategory = pathSegments?.length === 4 ? pathSegments[pathSegments.length - 2] : pathSegments[pathSegments.length - 3];
    const [logoUrl, setLogoUrl] = useLogoImage();

    useEffect(() => {
        const fetchData = async () => {
            if (subCategory && mainCategory) {
                await MainListCategories();
                await GetSubCategoryList();
            }
        };

        fetchData();
    }, [subCategory, mainCategory]);

    const MainListCategories = () => {
        let filter = {
            pk: {
                eq: "MAIN#"
            },
            slug: {
                eq: mainCategory
            }
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setcategoryList)
    };

    const GetSubCategoryList = () => {
        let filter = {
            pk: {
                beginsWith: 'SUB#',
            },
            slug: {
                eq: subCategory
            }
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setSubCategoryList)
    };

    useEffect(() => {
        if (categoryList?.severity === "success") {
            const item = categoryList?.data?.data?.listCategories?.items
            setCategory(item)
        }
    }, [categoryList])

    useEffect(() => {
        if (subCategoryList?.severity === "success") {
            const items = subCategoryList?.data?.data?.listCategories?.items || [];
            const filtered = items?.filter(data => data?.mainCategoryID === category?.[0]?.id);
            if (filtered?.length > 0) {
                setSubCategoryDetail(filtered[0])
                setSubCategoryId(filtered[0]?.id)
                setMainCategoryId(filtered[0]?.mainCategoryID)
            }
        }
    }, [subCategoryList, category]);

    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    };

    return (
        <>
            <HelmetProvider><Helmet>
                <title>
                    {logoUrl?.brandName ? `${logoUrl.brandName} | ${_.capitalize(subCategory)}` : Website?.WEBSITE}
                </title>
                <meta name="description" content={stripHtmlTags(subCategoryDetail?.description)} />
                <meta name="keywords" content="MEP Products, Buy MEP Supplies, {_.capitalize(subCategory)}, MEP Supplier in Qatar, Online MEP Store" />
                <link rel="canonical" href={`https://jerseymep.com/${subCategory}`} />

                <meta property="og:title" content={`${_.capitalize(subCategory)} | ${logoUrl?.brandName || "Jersey MEP | Online MEP Supplier in Qatar"}`} />
                <meta property="og:description" content={stripHtmlTags(subCategoryDetail?.description)} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://jerseymep.com/${subCategory}`} />
                
                <meta name="google-site-verification" content="RXij32NBqFjBA49RwlgxRaTYJSf0VvmVxAjYc27TlWE" />
            </Helmet></HelmetProvider>

            <ProductListQuery page="subCategory"
                mainCategoryId={mainCategoryId}
                subCategoryId={subCategoryId}
                mainCategory={mainCategory}
                subCategory={subCategory}
                subCategoryDetail={subCategoryDetail} />
        </>
    )
};

export default SubCategoryList