import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import ReturnCancelIcon from "../assets/images/icons/return-icon.png";
import {UpdateOrderItem} from '../utils/generalMethod';
import { showErrorToastr, showSuccessToastr } from "../common/toastrService"

function CancelReturn({ setFilterOrderItemDetail, filterOrderItemDetail, GetOrdersDetails, setCancelReturn, cancelReturn,orderDetail,setOrderItem }) {
    const [cancelReturnResponse, setCancelReturnResponse] = useState(null)

    const handleClose = () => setCancelReturn({show:false,orderItem:null});

    const UpdateCancelReturn = (e) =>{
        e.preventDefault();
        let returnData = {
            ...cancelReturn?.orderItem.returnRefundActivity,
            status: "Cancelled",
            returnStatusUpdate: [...cancelReturn?.orderItem.returnRefundActivity?.returnStatusUpdate, {
                returnStatus: "Cancelled",
                updatedAt: new Date(),
                updatedBy: orderDetail?.userName
            }],
            createdAt: new Date()
        }
        delete returnData?.__typename;
        delete returnData?.returnAddress?.__typename;
        delete returnData?.pickUpDetails?.pickUpAddress?.__typename;
        delete returnData?.pickUpDetails?.__typename;

        returnData?.returnStatusUpdate?.map((item, index) => { return delete item?.__typename; })
        let data = {
            pk: cancelReturn?.orderItem?.pk,
            id: cancelReturn?.orderItem?.id,
            returnRefundActivity: returnData
        }
        UpdateOrderItem(data, setCancelReturnResponse)
    }

    
    useEffect(() => {
        if (cancelReturnResponse?.severity === "success") {
            showSuccessToastr("Return cancelled successfully.")
            setCancelReturnResponse(null)
            setOrderItem(cancelReturnResponse?.data?.data?.updateOrderItem)
            setFilterOrderItemDetail(prevDetails => 
                prevDetails.map(item => 
                    item.id === cancelReturnResponse?.data?.data?.updateOrderItem.id 
                        ? { ...item, ...cancelReturnResponse?.data?.data?.updateOrderItem }
                        : item
                )
            );            
            handleClose();
        }
    }, [cancelReturnResponse])


    return (
        <>
            <Modal show={cancelReturn?.show} onHide={handleClose} animation={false} size="sm" className='cancel-return update-modal' aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Row>
                        <Col md={12} className='text-center my-2'>
                            <img src={ReturnCancelIcon} loading='lazy' />
                            <h4>Cancel Return?</h4>
                            <p>Are you sure you want to cancel your return request for order {orderDetail?.id}?</p>
                        </Col>
                        <Col md={12} className='text-center bottom-buttons'>
                            <button type='button' className='btn cancel-btn mb-2 me-2' onClick={handleClose}>Cancel</button>
                            <Link to="" style={{ color: "inherit" }} className="text-decoration-none">
                                <button type='button' className='btn shop-btn mb-2' onClick={(e)=>{UpdateCancelReturn(e)}}>Continue</button>
                            </Link>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CancelReturn;