import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Card, CardBody, Col, Row } from 'react-bootstrap';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 8.682928764058133,
  lng: 77.73327728786936,
};

const MapView = ({ setShowMapView, setAddress, ChooseAddressFromMap }) => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [addressDetail, setAddressDetail] = useState(null);
  const [addressDisplay, setAddressDisplay] = useState('');

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedPosition({ lat, lng });
    getAddressFromLatLng(lat, lng);
  };

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
      );

      if (response.ok) {
        const data = await response.json();
        if (data?.display_name) {
          setAddressDetail(data);
          let strDisplay = [
            data?.address?.house_number,
            data?.address?.village || data?.address?.road || data?.address?.industrial,
            data?.address?.city,
            data?.address?.county || data?.address?.municipality,
            data?.address?.state_district || data?.address?.district || data?.address?.town,
            data?.address?.state,
            data?.address?.country,
            data?.address?.postcode ? ` - ${data?.address?.postcode}` : '',
          ]
            .filter(Boolean)
            .join(',<br/>');

          setAddressDisplay(strDisplay);
        }
      } else {
        console.error('Error fetching address:', response.statusText);
      }
    } catch (error) {
      console.error('Fetch request failed:', error);
    }
  };

  const handleAddressSelection = () => {
    ChooseAddressFromMap(addressDetail);
  };

  const backToPreviousTab = () => {
    setShowMapView(false);
    setAddress(true);
  };

  return (
    <section id="mapViewSection">
      <Row className="mb-3">
        <Col md={12}>
          <Card className="mt-1">
            <CardBody className="py-2">
              <i onClick={backToPreviousTab} className="fa fa-chevron-left"></i>
            </CardBody>
          </Card>
        </Col>
        <Col md={addressDetail ? 9 : 12} className="mt-1">
          <Card>
            <CardBody>
              <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={10}
                  center={selectedPosition || center}
                  onClick={handleMapClick}
                >
                  {selectedPosition && <Marker position={selectedPosition} />}
                </GoogleMap>
              </LoadScript>
            </CardBody>
          </Card>
        </Col>
        {addressDisplay && (
          <Col md={3} className="mt-1">
            <Card>
              <CardBody>
                <div className="address-tile">
                  <div className="user-detail">
                    <p dangerouslySetInnerHTML={{ __html: addressDisplay }} />
                  </div>
                  <button className="btn shop-btn" onClick={handleAddressSelection}>
                    Use this address
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </section>
  );
};

export default MapView;