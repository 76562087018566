import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Card, CardBody, Col, Row } from "react-bootstrap";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
    lat: 8.682928764058133,
    lng: 77.73327728786936,
  };

const MapContainer = () => {
  const lat = parseFloat(sessionStorage.getItem("latitude"));
  const lng = parseFloat(sessionStorage.getItem("longitude"));

  const center = lat && lng ? { lat, lng: -lng } : defaultCenter; // Ensure center has valid values

  return (
    <section id="storeLocationSection">
      <Row className="mb-3">
        <Col md={12}>
          <Card className="mt-1">
            <CardBody className="py-2">
              <i className="fa fa-chevron-left"></i>
            </CardBody>
          </Card>
        </Col>
        <Col md={12} className="mt-1">
          <Card>
            <CardBody>
              <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
                <GoogleMap mapContainerStyle={mapContainerStyle} zoom={10} center={center}>
                  {lat && lng && <Marker position={{ lat, lng: -lng }} />}
                </GoogleMap>
              </LoadScript>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default MapContainer;
